import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import clsx from 'clsx';
import WeekCalendar from '../components/DateTime/WeekCalendar';
import api from '../api/api';
import Chart from 'react-apexcharts';

interface DataRowModel {
  id: GridRowId;
  [avg: string]: number | string;
}

interface GridData {
  columns: GridColDef[];
  rows: DataRowModel[];
}

type resultData = {
  HOUR: number;
  WEEKDAY: number;
  WEEKOFYEAR: number;
  AVG: number;
};

export default function AmpersByDayAndHour() {
  const [data, setData] = React.useState<GridData>({ columns: [], rows: [] });
  const [startDay, set_startDay] = React.useState<string>();
  const [lastDay, set_lastDay] = React.useState<string>();
  const [ChartData, setChartData] =
    React.useState<{ data: { x: string; y: any }[]; name: string }[]>();

  const DaysOfWeek = (start: string, end: string) => {
    set_startDay(start);
    set_lastDay(end);
  };

  const StatOptions = async () => {
    // console.log(startDay + ' 00:00:00');
    // console.log(lastDay + ' 23:59:59');

    const data = {
      start: startDay + ' 00:00:00',
      end: lastDay + ' 23:59:59',
    };

    const response = await api.AmpersByDayAndHour(data);

    // console.log(response);
    // const rows: DataRowModel[] = [];

    // for (let i = 0; i < 24; i += 1) {
    //   const row: DataRowModel = {
    //     id: i,
    //   };
    //   for (let j = 0; j <= 7; j += 1) {
    //     if (j == 0) {
    //       if (i == 0) {
    //         row[`avg${j}M`] = `00:00 - 01:00`;
    //       } else if (i == 1) {
    //         row[`avg${j}M`] = `01:00 - 02:00`;
    //       } else if (i == 2) {
    //         row[`avg${j}M`] = `02:00 - 03:00`;
    //       } else if (i == 3) {
    //         row[`avg${j}M`] = `03:00 - 04:00`;
    //       } else if (i == 4) {
    //         row[`avg${j}M`] = `04:00 - 05:00`;
    //       } else if (i == 5) {
    //         row[`avg${j}M`] = `05:00 - 06:00`;
    //       } else if (i == 6) {
    //         row[`avg${j}M`] = `06:00 - 07:00`;
    //       } else if (i == 7) {
    //         row[`avg${j}M`] = `07:00 - 08:00`;
    //       } else if (i == 8) {
    //         row[`avg${j}M`] = `08:00 - 09:00`;
    //       } else if (i == 9) {
    //         row[`avg${j}M`] = `09:00 - 10:00`;
    //       } else if (i == 10) {
    //         row[`avg${j}M`] = `10:00 - 11:00`;
    //       } else if (i == 11) {
    //         row[`avg${j}M`] = `11:00 - 12:00`;
    //       } else if (i == 12) {
    //         row[`avg${j}M`] = `12:00 - 13:00`;
    //       } else if (i == 13) {
    //         row[`avg${j}M`] = `13:00 - 14:00`;
    //       } else if (i == 14) {
    //         row[`avg${j}M`] = `14:00 - 15:00`;
    //       } else if (i == 15) {
    //         row[`avg${j}M`] = `15:00 - 16:00`;
    //       } else if (i == 16) {
    //         row[`avg${j}M`] = `16:00 - 17:00`;
    //       } else if (i == 17) {
    //         row[`avg${j}M`] = `17:00 - 18:00`;
    //       } else if (i == 18) {
    //         row[`avg${j}M`] = `18:00 - 19:00`;
    //       } else if (i == 19) {
    //         row[`avg${j}M`] = `19:00 - 20:00`;
    //       } else if (i == 20) {
    //         row[`avg${j}M`] = `20:00 - 21:00`;
    //       } else if (i == 21) {
    //         row[`avg${j}M`] = `21:00 - 22:00`;
    //       } else if (i == 22) {
    //         row[`avg${j}M`] = `22:00 - 23:00`;
    //       } else if (i == 23) {
    //         row[`avg${j}M`] = `23:00 - 00:00`;
    //       }
    //     } else {
    //       // console.log(j - 1, i);

    //       // console.log(response);
    //       // console.log(response[j - 1]);
    //       // console.log(response[j - 1][i]);
    //       // console.log(response[j - 1][i]?.AVG);
    //       response
    //         ? response[j - 1]
    //           ? (row[`avg${j}M`] = `${
    //               response[j - 1][i] ? response[j - 1][i].AVG.toString().substr(0, 5) : 'нет данных'
    //             }`)
    //           : (row[`avg${j}M`] = 'нет данных')
    //         : (row[`avg${j}M`] = 'нет данных');
    //     }
    //   }

    //   rows.push(row);
    // }

    // const columns: GridColDef[] = [];

    // for (let j = 0; j <= 7; j += 1) {
    //   columns.push({
    //     field: `avg${j}M`,
    //     headerName:
    //       j == 1
    //         ? 'Понедельник'
    //         : j == 2
    //         ? 'Вторник'
    //         : j == 3
    //         ? 'Среда'
    //         : j == 4
    //         ? 'Четверг'
    //         : j == 5
    //         ? 'Пятница'
    //         : j == 6
    //         ? 'Суббота'
    //         : j == 7
    //         ? 'Воскресенье'
    //         : '',
    //     width: 150,
    //     cellClassName: (params) => {
    //       if (params.value == null) {
    //         return '';
    //       }

    //       return clsx('super-app', {
    //         negative: params.value < 1,
    //       });
    //     },
    //   });
    // }

    // setData({
    //   rows,
    //   columns,
    // });

    setChartData(generateData(response));
  };

  function generateData(arg1: any[]) {
    // var response_lenght = arg1.length;
    var data: { data: { x: string; y: any }[]; name: string }[] = [];
    // console.log(arg1);
    for (let j = 6; j >= 0; j -= 1) {
      // arg1.map((datas: { AVG: any }[], j) => {
      // console.log(datas);
      // console.log(datas.length);

      var len24 = arg1[j] ? (arg1[j].length ? arg1[j].length : 24) : 24;
      var series = [];
      var i = 0;
      // console.log(len24);
      while (i < len24) {
        var x = '';
        // console.log(i);
        if (i == 0) {
          x = `00:00 - 01:00`;
        } else if (i == 1) {
          x = `01:00 - 02:00`;
        } else if (i == 2) {
          x = `02:00 - 03:00`;
        } else if (i == 3) {
          x = `03:00 - 04:00`;
        } else if (i == 4) {
          x = `04:00 - 05:00`;
        } else if (i == 5) {
          x = `05:00 - 06:00`;
        } else if (i == 6) {
          x = `06:00 - 07:00`;
        } else if (i == 7) {
          x = `07:00 - 08:00`;
        } else if (i == 8) {
          x = `08:00 - 09:00`;
        } else if (i == 9) {
          x = `09:00 - 10:00`;
        } else if (i == 10) {
          x = `10:00 - 11:00`;
        } else if (i == 11) {
          x = `11:00 - 12:00`;
        } else if (i == 12) {
          x = `12:00 - 13:00`;
        } else if (i == 13) {
          x = `13:00 - 14:00`;
        } else if (i == 14) {
          x = `14:00 - 15:00`;
        } else if (i == 15) {
          x = `15:00 - 16:00`;
        } else if (i == 16) {
          x = `16:00 - 17:00`;
        } else if (i == 17) {
          x = `17:00 - 18:00`;
        } else if (i == 18) {
          x = `18:00 - 19:00`;
        } else if (i == 19) {
          x = `19:00 - 20:00`;
        } else if (i == 20) {
          x = `20:00 - 21:00`;
        } else if (i == 21) {
          x = `21:00 - 22:00`;
        } else if (i == 22) {
          x = `22:00 - 23:00`;
        } else if (i == 23) {
          x = `23:00 - 00:00`;
        } else {
          arg1
            ? arg1[j][j - 1]
              ? (x = `${
                  arg1[j][j - 1].AVG ? arg1[j][j - 1].AVG.AVG.toString().substr(0, 5) : 'нет данных'
                }`)
              : (x = 'нет данных')
            : (x = 'нет данных');
        }

        var y = arg1[j] ? (arg1[j][i] ? (arg1[j][i].AVG ? arg1[j][i].AVG : 0) : 0) : 0;

        series.push({
          x: x,
          y: y,
        });

        i++;
      }

      // console.log('j:', j);
      // console.log(arg1);

      data.push({
        data: series,
        name:
          j == 0
            ? 'Понедельник'
            : j == 1
            ? 'Вторник'
            : j == 2
            ? 'Среда'
            : j == 3
            ? 'Четверг'
            : j == 4
            ? 'Пятница'
            : j == 5
            ? 'Суббота'
            : j == 6
            ? 'Воскресенье'
            : '',
      });
    }

    return data;
  }

  interface ApexOptions {
    annotations?: ApexAnnotations;
    chart?: ApexChart;
    colors?: any[];
    dataLabels?: ApexDataLabels;
    fill?: ApexFill;
    forecastDataPoints?: ApexForecastDataPoints;
    grid?: ApexGrid;
    labels?: string[];
    legend?: ApexLegend;
    markers?: ApexMarkers;
    noData?: ApexNoData;
    plotOptions?: ApexPlotOptions;
    responsive?: ApexResponsive[];
    series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
    states?: ApexStates;
    stroke?: ApexStroke;
    subtitle?: ApexTitleSubtitle;
    theme?: ApexTheme;
    title?: ApexTitleSubtitle;
    tooltip?: ApexTooltip;
    xaxis?: ApexXAxis;
    yaxis?: ApexYAxis | ApexYAxis[];
  }

  const options: ApexOptions = {
    chart: {
      height: 800,
      type: 'heatmap',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#008FFB'],
    title: {
      // text: 'Линии по постам',
      align: 'center',
      margin: 20,
      style: {
        color: 'rgb(95, 116, 141)',
        fontSize: '13px',
        fontWeight: '600',
        fontFamily: 'Montserrat, sans-serif',
      },
    },
    plotOptions: {
      heatmap: {
        distributed: false,
        colorScale: {
          ranges: [
            {
              from: 0.1,
              to: 5,
              color: '#128FD9',
              name: 'тип 1',
            },
            {
              from: -1,
              to: 0.0,
              color: '#000000',
              name: 'тип 2',
            },
          ],
        },
      },
    },
  };

  return (
    <>
      <WeekCalendar DaysOfWeek={DaysOfWeek} StatOptions={StatOptions} />
      <Box
        sx={{
          height: 800,
          background: '#fff',
          borderRadius: '10px',
          '& .super-app.negative': {
            backgroundColor: '#000000',
            color: '#ff0000',
            fontWeight: '600',
            textAlign: 'center',
          },
        }}
      >
        {/* <DataGrid
          {...data}
          //   rows={result}
          //   columns={columns}
          getRowId={() => Math.floor(Math.random() * 100000000)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        /> */}

        {ChartData ? (
          <div id='chart'>
            <Chart
              options={options}
              series={ChartData}
              type='heatmap'
              height={'730px'}
              width={'100%'}
            />
          </div>
        ) : null}
      </Box>
    </>
  );
}
