const api = {
  async Login(data: any) {
    console.log(data);
    const response = await fetch('https://lumen.bikli.kz/Api/api/users/auth.php', {
      method: 'POST',
      body: JSON.stringify({ ...data }),
    });
    const jsonData = await response.json();
    return jsonData;
  },

  async dataGet() {
    const response = await fetch('https://lumen.bikli.kz/Api/api/getGroups.php', {
      method: 'GET',
    });
    const jsonData = await response.json();
    return jsonData;
  },

  async getUserAll() {
    const response = await fetch('https://lumen.bikli.kz/Api/api/users/getAllUsers.php', {
      method: 'POST',
    });
    const jsonData = await response.json();
    return jsonData;
  },

  async getUsersPostAccess(data: any) {
    const response = await fetch('https://lumen.bikli.kz/Api/api/users/getPostAccessUsers.php', {
      method: 'POST',
      body: JSON.stringify({ ...data }),
    });
    const jsonData = await response.json();
    return jsonData;
  },

  async getUsersPostAccessWithData(data: any) {
    const response = await fetch(
      'https://lumen.bikli.kz/Api/api/users/getPostAccessUsersWithData.php',
      {
        method: 'POST',
        body: JSON.stringify({ ...data }),
      },
    );
    const jsonData = await response.json();
    return jsonData;
  },

  async usersPostAccessChange(data: any) {
    const response = await fetch('https://lumen.bikli.kz/Api/api/users/usersPostAccessChange.php', {
      method: 'POST',
      body: JSON.stringify({ ...data }),
    });
    const jsonData = await response.json();
    return jsonData;
  },

  async addUser(data: any) {
    const response = await fetch('https://lumen.bikli.kz/Api/api/users/addUser.php', {
      method: 'POST',
      body: data,
    });
    const jsonData = await response.json();
    return jsonData;
  },

  async getPostLinesbyID(data: any) {
    const response = await fetch(
      'https://lumen.bikli.kz/Api/api/street_lights/getPostLinesbyID.php',
      {
        method: 'POST',
        body: JSON.stringify({ ...data }),
      },
    );
    const jsonData = await response.json();
    return jsonData;
  },

  async getUniqGroupsAndLines() {
    const response = await fetch(
      'https://lumen.bikli.kz/Api/api/street_lights/getUniqGroupsAndPosts.php',
      {
        method: 'GET',
      },
    );
    const jsonData = await response.json();
    return jsonData;
  },

  async newMarkersReg(data: any) {
    const response = await fetch('https://lumen.bikli.kz/Api/api/street_lights/newMarkersReg.php', {
      method: 'POST',
      body: JSON.stringify({ ...data }),
    });
    const jsonData = await response.json();
    return jsonData;
  },

  async newPostReg(data: any) {
    const response = await fetch('https://lumen.bikli.kz/Api/api/street_lights/newPostReg.php', {
      method: 'POST',
      body: JSON.stringify({ ...data }),
    });
    const jsonData = await response.json();
    return jsonData;
  },

  async AmpersByDayAndHour(data: any) {
    const response = await fetch(
      'https://lumen.bikli.kz/Api/api/statistics/getByDaysAndHours.php',
      {
        method: 'POST',
        body: JSON.stringify({ ...data }),
      },
    );
    const jsonData = await response.json();
    return jsonData;
  },

  async PostsGet() {
    const response = await fetch('https://lumen.bikli.kz/Api/api/street_lights/getPosts.php', {
      method: 'POST',
    });
    const jsonData = await response.json();
    return jsonData;
  },

  async getAndFormateAmpers(data: any) {
    const response = await fetch(
      'https://lumen.bikli.kz/Api/api/statistics/getAndFormateAmpers.php',
      {
        method: 'POST',
        body: JSON.stringify({ ...data }),
      },
    );
    const jsonData = await response.json();
    return jsonData;
  },

  async sendFiles(data: any) {
    const response = await fetch(
      'https://lumen.bikli.kz/Api/api/statistics/getAndFormateAmpersфыв.php',
      {
        method: 'POST',
        body: JSON.stringify({ ...data }),
      },
    );
    const jsonData = await response.json();
    return jsonData;
  },
  async replaceFiles(data: any) {
    const response = await fetch(
      'https://lumen.bikli.kz/Api/api/statistics/getAndFormateAmpersфыв.php',
      {
        method: 'POST',
        body: JSON.stringify({ ...data }),
      },
    );
    const jsonData = await response.json();
    return jsonData;
  },
  async deletePhoto(data: any) {
    const response = await fetch(
      'https://lumen.bikli.kz/Api/api/statistics/getAndFormateAmpersфыв.php',
      {
        method: 'POST',
        body: JSON.stringify({ ...data }),
      },
    );
    const jsonData = await response.json();
    return jsonData;
  },

  async fetchGallery(page: number) {
    const response = await fetch(
      'https://lumen.bikli.kz/Api/api/statistics/getAndFormateAmpersфыв.php',
      {
        method: 'POST',
        body: JSON.stringify({ page }),
      },
    );
    const jsonData = await response.json();
    return jsonData;
  },
};

export default api;
