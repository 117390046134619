import React from 'react';
import AmperLightValues from '../components/DataTable/AmperLightValues';
const Content = () => {
  return (
    <>
      <div className='MainPageBody'>
        <AmperLightValues />
      </div>
    </>
  );
};

export default Content;
