import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Coordinates {
  course: number;

  lat: number;
  lon: number;
  number: string;
  model: string;
  gosnumber: string;
}

interface ArrowPolylineCoordinates {
  arrow_lat: number;
  arrow_lon: number;
  bus_lat: number;
  bus_lon: number;
}

type CurrentDisplayState = {
  BusCors: Coordinates[];
  ArrowCors: ArrowPolylineCoordinates[];
};

let initialState: CurrentDisplayState = {
  BusCors: [],
  ArrowCors: [],
};

const MapControlSlice = createSlice({
  name: 'MapControl',
  initialState,
  reducers: {
    setCurrentBusCors(state, action: PayloadAction<Coordinates[]>) {
      state.BusCors = action.payload;
    },

    setCurrentArrowCors(state, action: PayloadAction<ArrowPolylineCoordinates[]>) {
      state.ArrowCors = action.payload;
    },
  },
});

export const { setCurrentBusCors, setCurrentArrowCors } = MapControlSlice.actions;

export default MapControlSlice.reducer;
