import * as React from 'react';
import MobileButtons from './MobileButtons';
import PCButtons from './PCButtons';

interface Props {}

const MainHeader = (props: Props) => {
  const isMobile = localStorage.getItem('isMobile');

  return <>{isMobile == 'true' ? <MobileButtons /> : <PCButtons />}</>;
};

export default MainHeader;
