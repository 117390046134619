import React, { CSSProperties } from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import api from '../api/api';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import s from '../Styles/BoxPage/BoxDetails.module.css';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Chip from '@mui/material/Chip';
import FirstBlock from '../components/AddUser/FirstBlock';
import SecondBlock from '../components/AddUser/SecondBlock';
import { successData, errorData } from '../Common/toasts/sliceToasts';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const GridStyle: CSSProperties = {
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'row wrap',
  marginTop: '-24px',
  width: 'calc(100% + 24px)',
  marginLeft: '-24px',
  justifyContent: 'center',
};

type DataObj = {
  ValueFirstName: string;
  ValueSecondName: string;
  ValueFatherName: string;
  ValueLogin: string;
  ValuePass: string;
  ValueEmail: string;
};

const AddUser = () => {
  const isMobileDevice = localStorage.getItem('isMobile');

  // const [Valuephoto, setValuephoto] = React.useState([]);
  const [ValueDataObj, setValueDataObj] = React.useState<DataObj>();
  const [DisableButtonSend, setDisableButtonSend] = React.useState(true);

  const FormDataPhoto = async (dataPhoto: any) => {
    // setValuephoto(data)

    const fd = new FormData();
    fd.append('img', dataPhoto[0]);
    fd.append('aaa', '123');

    for (var key in ValueDataObj) {
      fd.append(key, ValueDataObj[key as keyof typeof ValueDataObj]);
    }

    const resp = await api.addUser(fd);

    if (resp) {
      successData('Пользователь успешно добавлен');
    } else {
      errorData('Возникла ошибка');
    }
  };

  const FormDataObj = (data: DataObj) => {
    setValueDataObj(data);
    setDisableButtonSend(false);
  };

  // console.log(Valuephoto);
  console.log(ValueDataObj);

  return (
    <>
      <Box mt={2} mb={2}>
        <Grid container sx={GridStyle}>
          <FirstBlock FormDataObj={FormDataObj} />
          <SecondBlock FormData={FormDataPhoto} DisableButtonSend={DisableButtonSend} />
        </Grid>
      </Box>
    </>
  );
};

export default AddUser;
