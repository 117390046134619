import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MainPage from '../../Pages/MainPage';
import Notifications from '../../Pages/Notifications';
import Map from '../../Pages/Map';
import AmperLightGroups from '../../Pages/AmperLightGroups';
import AmperLightValues from '../../Pages/AmperLightValues';
import TWTable from '../../Pages/TWTable';
import AmpersByDayAndHour from '../../Pages/AmpersByDayAndHour';
import Boxes from '../../Pages/Boxes';
import BoxPage from '../../Pages/BoxPage';
import StreetLightPosts from '../../Pages/StreetLightPosts';
import StreetLightPostPage from '../../Pages/StreetLightPostPage';
import AddUser from '../../Pages/AddUser';
import UsersList from '../../Pages/UsersList';

const MainRoutes = ({}) => {
  return (
    <Routes>
      <Route path='/' element={<MainPage />} />
      <Route path='/map' element={<Map />} />
      <Route path='/street-light-groups' element={<AmperLightGroups />} />
      <Route path='/street-light-values' element={<AmperLightValues />} />
      <Route path='/street-light-by-day-hour' element={<AmpersByDayAndHour />} />
      <Route path='/temp-water' element={<TWTable />} />
      <Route path='/notifications' element={<Notifications />} />
      <Route path='/boxes' element={<Boxes />} />
      <Route path='/box/:id' element={<BoxPage />} />
      <Route path='/posts' element={<StreetLightPosts />} />
      <Route path='/post/:id' element={<StreetLightPostPage />} />
      <Route path='/add-user' element={<AddUser />} />
      <Route path='/users' element={<UsersList />} />
    </Routes>
  );
};

export default MainRoutes;
