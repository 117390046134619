import React from 'react';

function list(files) {
  const label = (file) => `'${file.name}' of size '${file.size}' and type '${file.type}'`;
  return files.map((file) => <li key={file.name}>{label(file)}</li>);
}

export const FileList = ({ files }) => {
  if (files.length === 0) {
    return <div></div>;
  }
  console.log(files);

  console.log(files[0]);
  return (
    <div>
      <div style={{ marginTop: '20px' }}>Количество загружаемых файлов: {files.length}</div>
      {Array.from(files).forEach((file) => {
        return (
          <div>
            Имя: {file.name} ----- Тип: {file.type} ----- Размер: {file.size} байт
          </div>
        );
      })}
    </div>
  );
};
