import React from 'react';
import NotificationsTable from '../components/Notifications/table';
const Notifications = () => {
  return (
    <>
      <div className='MainPageBody'>
        <NotificationsTable />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Notifications;
