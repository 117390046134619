import React from 'react';
import FirstPage from './FirstPage';
const Content = () => {
  return (
    <>
      <div className='MainPageBody'>
        <FirstPage />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Content;
