const StylesModule = {
  Box_1: {
    paddingTop: '16px',
    paddingBottom: '32px',
  },

  Grid: {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row wrap',
    width: '100%',
  },

  Grid_1: {
    flexBasis: '75%',
    flexGrow: '0',
    maxWidth: '75%',
  },

  Grid_2: {
    flexBasis: '25%',
    flexGrow: '0',
    maxWidth: '25%',
  },

  Paper_1: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(18, 31, 67)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundImage: 'none',
    overflow: 'hidden',
    boxShadow:
      'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
    borderRadius: '8px',
    height: '100%',
  },
  Paper_2: {
    height: '100%',
    backgroundColor: 'rgb(255 255 255)',
    marginLeft: '10px',
    color: 'rgb(18, 31, 67)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundImage: 'none',
    overflow: 'hidden',
    boxShadow:
      'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
    borderRadius: '8px',
  },

  BoxesSubHeadStyle: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap',
    margin: '24px 0px',
    justifyContent: 'center',
  },

  BoxesHeadButtonStyle: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    WebkitTapHighlightColor: 'transparent',
    outline: '0px',
    border: '0px',
    margin: '0px',
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    textDecoration: 'none',
    fontFamily: 'Montserrat, sans-serif',
    lineHeight: '1.75',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#2499ef',
    textTransform: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '0.6rem 1.5rem',
    fontSize: '12px',
    fontWeight: '600',
    minWidth: '84px',
    color: 'white',
  },

  BoxType: {
    fontFamily: 'Montserrat,sans-serif',
    fontWeight: '550',
    fontSize: '15px',
    lineHeight: '1.5',
    letterSpacing: '3px',
    color: '#424951c4',
    textAlign: 'center',
  },

  Avatar: {
    width: '100px',
    height: '100px',
    margin: 'auto',
    marginTop: '65px',
    marginBottom: '20px',
    background: '#2499ef',
  },

  Paper_3: {
    color: 'rgb(18, 31, 67)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundImage: 'none',
    overflow: 'hidden',
    boxShadow:
      'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
    borderRadius: '8px',
    height: '100%',
    backgroundColor: '#f2f4f9',
    marginTop: '60px',
    flexDirection: 'column',
    justifyContent: 'space-around',
    display: 'flex',
  },

  BoxIconStyle: {
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '0',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: '#0288d1 !important',
    fontSize: '16px',
  },

  BoxTypeStyle: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '1.65',
    color: 'rgb(95, 116, 141)',
    fontFamily: 'Montserrat, sans-serif',
    margin: '0px 0px 0px 8px',
  },

  Button: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    WebkitTapHighlightColor: 'transparent',
    outline: '0px',
    border: '0px',
    margin: '0px',
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    textDecoration: 'none',
    fontFamily: 'Montserrat, sans-serif',
    lineHeight: '1.75',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#2499ef',
    textTransform: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '0.6rem 1.5rem',
    fontSize: '12px',
    fontWeight: '600',
    minWidth: '84px',
    color: 'white',
    marginTop: '45px',
  },
};

export default StylesModule;
