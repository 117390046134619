import React, { CSSProperties, MutableRefObject } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import HubIcon from '@mui/icons-material/Hub';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import TableChartIcon from '@mui/icons-material/TableChart';
import PublicIcon from '@mui/icons-material/Public';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import s from './style.module.css';
import logo from '../../Styles/img/logo.png';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { setMenuOpen, setSideMenuWidth } from '../../redux/slices/AppControlSlice';
import EventIcon from '@mui/icons-material/Event';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupsIcon from '@mui/icons-material/Groups';
const SideMenu = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const divBlock = React.useRef<HTMLDivElement>(null);
  const { menuOpen } = useSelector((state: RootState) => state.AppControl);
  const url = window.location.href.split('/')[3];
  const [open, setOpen] = React.useState(0);

  const isMobileDevice = localStorage.getItem('isMobile');

  const handleClick = (val: number) => {
    val == open ? setOpen(0) : setOpen(val);
  };

  const menuClose = async () => {
    dispatch(setMenuOpen(false));
  };

  React.useEffect(() => {
    if (divBlock.current) {
      const width = divBlock.current.getBoundingClientRect().width;
      dispatch(setSideMenuWidth(width));
    }
  }, []);

  const menuMobileStyles: CSSProperties = {
    background: '#25272ea6',
    bottom: '-50%',
    boxSizing: 'border-box',
    padding: '16px 44px',
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: '12',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: menuOpen ? 'flex' : 'none',
    border: 'none',
    borderRadius: '0',
    backdropFilter: 'blur(5px)',
    margin: 'auto',
  };

  return (
    <>
      <div
        className={s.Wrapper}
        style={isMobileDevice == 'true' ? menuMobileStyles : {}}
        ref={divBlock}
      >
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            mt: isMobileDevice == 'true' ? '90px' : '0',
            maxHeight: isMobileDevice == 'true' ? '450px' : 'inherit',
            borderRadius: isMobileDevice == 'true' ? '10px' : '0',
          }}
          component='nav'
          aria-labelledby='nested-list-subheader'
          subheader={
            <ListSubheader component='div' id='nested-list-subheader'>
              {isMobileDevice !== 'true' ? (
                <div
                  style={{
                    cursor: 'pointer',
                    background: '#2499ef',
                    margin: 'auto',
                    width: '100%',
                    marginTop: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '65px',
                    border: '8px solid #fff',
                    marginBottom: '24px',
                    borderRadius: '20px',
                  }}
                  className={s.AppHeaderPart1}
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  <img src={logo} className={s.AppLogo} alt='logo' />
                </div>
              ) : null}
            </ListSubheader>
          }
        >
          <ListItemButton
            onClick={() => {
              menuClose();
              navigate('/');
              menuClose();
            }}
          >
            <ListItemIcon sx={{ minWidth: '35px' }}>
              <StarBorder color={url == '' ? 'info' : 'disabled'} />
            </ListItemIcon>
            <ListItemText
              sx={{
                transition: 'all 0.15s ease 0s',
                fontFamily: 'Montserrat,sans-serif',
                fontWeight: '600',
                fontSize: '15px',
                lineHeight: '1.5',
                letterSpacing: '1px',
                color: 'rgb(95 116 141 / 77%)',
              }}
              primary='Главная'
            />
          </ListItemButton>

          <ListItemButton
            onClick={() => {
              handleClick(2);
            }}
          >
            <ListItemIcon sx={{ minWidth: '35px' }}>
              <FormatListBulletedIcon color={url == 'add-user' ? 'info' : 'disabled'} />
            </ListItemIcon>
            <ListItemText
              sx={{
                transition: 'all 0.15s ease 0s',
                fontFamily: 'Montserrat,sans-serif',
                fontWeight: '550',
                fontSize: '15px',
                lineHeight: '1.5',
                letterSpacing: '1px',
                color: 'rgb(95 116 141 / 77%)',
              }}
              primary='Администрирование'
            />
            {open == 2 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open == 2 ? true : false} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  menuClose();
                  navigate('/add-user');
                }}
              >
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <PersonAddIcon color={url == 'add-user' ? 'info' : 'disabled'} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    transition: 'all 0.15s ease 0s',
                    fontFamily: 'Montserrat,sans-serif',
                    fontWeight: '550',
                    fontSize: '15px',
                    lineHeight: '1.5',
                    letterSpacing: '1px',
                    color: 'rgb(95 116 141 / 77%)',
                  }}
                  primary='Добавить пользователя'
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  menuClose();
                  navigate('/users');
                }}
              >
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <GroupsIcon color={url == 'users' ? 'info' : 'disabled'} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    transition: 'all 0.15s ease 0s',
                    fontFamily: 'Montserrat,sans-serif',
                    fontWeight: '550',
                    fontSize: '15px',
                    lineHeight: '1.5',
                    letterSpacing: '1px',
                    color: 'rgb(95 116 141 / 77%)',
                  }}
                  primary='Пользователи'
                />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton
            onClick={() => {
              menuClose();
              navigate('/map');
            }}
          >
            <ListItemIcon sx={{ minWidth: '35px' }}>
              <PublicIcon color={url == 'map' ? 'info' : 'disabled'} />
            </ListItemIcon>
            <ListItemText
              sx={{
                transition: 'all 0.15s ease 0s',
                fontFamily: 'Montserrat,sans-serif',
                fontWeight: '550',
                fontSize: '15px',
                lineHeight: '1.5',
                letterSpacing: '1px',
                color: 'rgb(95 116 141 / 77%)',
              }}
              primary='Карта'
            />
          </ListItemButton>

          <ListItemButton
            onClick={() => {
              handleClick(1);
            }}
          >
            <ListItemIcon sx={{ minWidth: '35px' }}>
              <FormatListBulletedIcon
                color={
                  url == 'street-light-groups' ||
                  url == 'street-light-values' ||
                  url == 'street-light-by-day-hour'
                    ? 'info'
                    : 'disabled'
                }
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                transition: 'all 0.15s ease 0s',
                fontFamily: 'Montserrat,sans-serif',
                fontWeight: '550',
                fontSize: '15px',
                lineHeight: '1.5',
                letterSpacing: '1px',
                color: 'rgb(95 116 141 / 77%)',
              }}
              primary='Уличное освещение'
            />
            {open == 1 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open == 1 ? true : false} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  menuClose();
                  navigate('/street-light-groups');
                }}
              >
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <TableChartIcon color={url == 'street-light-groups' ? 'info' : 'disabled'} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    transition: 'all 0.15s ease 0s',
                    fontFamily: 'Montserrat,sans-serif',
                    fontWeight: '550',
                    fontSize: '15px',
                    lineHeight: '1.5',
                    letterSpacing: '1px',
                    color: 'rgb(95 116 141 / 77%)',
                  }}
                  primary='Группы'
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  menuClose();
                  navigate('/street-light-values');
                }}
              >
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <TableChartIcon color={url == 'street-light-values' ? 'info' : 'disabled'} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    transition: 'all 0.15s ease 0s',
                    fontFamily: 'Montserrat,sans-serif',
                    fontWeight: '550',
                    fontSize: '15px',
                    lineHeight: '1.5',
                    letterSpacing: '1px',
                    color: 'rgb(95 116 141 / 77%)',
                  }}
                  primary='Значения Групп'
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  menuClose();
                  navigate('/street-light-by-day-hour');
                }}
              >
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <EventIcon color={url == 'street-light-by-day-hour' ? 'info' : 'disabled'} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    transition: 'all 0.15s ease 0s',
                    fontFamily: 'Montserrat,sans-serif',
                    fontWeight: '550',
                    fontSize: '15px',
                    lineHeight: '1.5',
                    letterSpacing: '1px',
                    color: 'rgb(95 116 141 / 77%)',
                  }}
                  primary='Значения Групп по дням и часам'
                />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            onClick={() => {
              menuClose();
              navigate('/temp-water');
            }}
          >
            <ListItemIcon sx={{ minWidth: '35px' }}>
              <TableChartIcon color={url == 'temp-water' ? 'info' : 'disabled'} />
            </ListItemIcon>
            <ListItemText
              sx={{
                transition: 'all 0.15s ease 0s',
                fontFamily: 'Montserrat,sans-serif',
                fontWeight: '550',
                fontSize: '15px',
                lineHeight: '1.5',
                letterSpacing: '1px',
                color: 'rgb(95 116 141 / 77%)',
              }}
              primary='Температура Влажность'
            />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              menuClose();
              navigate('/boxes');
            }}
          >
            <ListItemIcon sx={{ minWidth: '35px' }}>
              <AllInboxIcon color={url == 'boxes' || url == 'box' ? 'info' : 'disabled'} />
            </ListItemIcon>
            <ListItemText
              sx={{
                transition: 'all 0.15s ease 0s',
                fontFamily: 'Montserrat,sans-serif',
                fontWeight: '550',
                fontSize: '15px',
                lineHeight: '1.5',
                letterSpacing: '1px',
                color: 'rgb(95 116 141 / 77%)',
              }}
              primary='ШУНО'
            />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              menuClose();
              navigate('/posts');
            }}
          >
            <ListItemIcon sx={{ minWidth: '35px' }}>
              <HubIcon color={url == 'posts' || url == 'post' ? 'info' : 'disabled'} />
            </ListItemIcon>
            <ListItemText
              sx={{
                transition: 'all 0.15s ease 0s',
                fontFamily: 'Montserrat,sans-serif',
                fontWeight: '550',
                fontSize: '15px',
                lineHeight: '1.5',
                letterSpacing: '1px',
                color: 'rgb(95 116 141 / 77%)',
              }}
              primary='Посты'
            />
          </ListItemButton>
        </List>
      </div>
    </>
  );
};

export default SideMenu;
