import React from 'react';
import Groups from '../components/DataTable/Groups';
import Map from '../components/Map/map';
const Content = () => {
  return (
    <>
      <div className='MainPageBody'>
        <Map />
      </div>
    </>
  );
};

export default Content;
