import React, { CSSProperties } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import isBetweenPlugin from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';
import updateLocale from 'dayjs/plugin/updateLocale';
import timezone from 'dayjs/plugin/timezone';

import { DateTime, Settings } from 'luxon';

import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import s from './calendar.module.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import StylesModule from './WeekCalendarStyles';
import moment from 'moment';
import ru from 'dayjs/locale/ru';

dayjs.extend(isBetweenPlugin);
dayjs.extend(localeData);
dayjs.extend(isoWeek);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);

// console.log(DateTime.fromISO('2022-04-17T15:30', { zone: 'UTC' }));
// console.log(dayjs.tz('2013-11-18 11:55:20', 'UTC'));
// dayjs().startOf('isoWeek');
// dayjs.updateLocale('ru', {
//   weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
// });

dayjs.locale({ ...ru, weekStart: 1 });
dayjs.Ls.ru.weekStart = 1;
// let ld = dayjs().localeData().weekdays();

// console.log(ld);
// console.log(dayjs().isoWeekday());
interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>;

function Day(props: PickersDayProps<Dayjs> & { selectedDay?: Dayjs | null }) {
  const { day, selectedDay, ...other } = props;

  if (selectedDay == null) {
    return <PickersDay day={day} {...other} />;
  }

  const start = selectedDay.startOf('week');
  const end = selectedDay.endOf('week');

  const dayIsBetween = day.isBetween(start, end, null, '[]');
  const isFirstDay = day.isSame(start, 'day');
  const isLastDay = day.isSame(end, 'day');

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  );
}

interface Props {
  DaysOfWeek: (startDay: string, lastDay: string) => void;
  StatOptions: () => void;
}

export default function WeekCalendar(props: Props) {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs().tz('Pacific/Easter', false));
  const [startDay, set_startDay] = React.useState<string>();
  const [lastDay, set_lastDay] = React.useState<string>();
  const isMobileDevice = localStorage.getItem('isMobile');

  const DayChange = (newValue: Dayjs | null) => {
    setValue(newValue);

    if (newValue == null) {
      console.log('null');
    } else {
      const startDay = newValue.startOf('week').format('YYYY-MM-DD');
      const lastDay = newValue.endOf('week').format('YYYY-MM-DD');

      set_startDay(startDay);
      set_lastDay(lastDay);

      props.DaysOfWeek(startDay, lastDay);
    }
  };

  return (
    <Box sx={StylesModule.BoxStyle}>
      <Paper sx={StylesModule.PaperStyle}>
        <Grid container sx={StylesModule.GridStyle}>
          <Grid
            sx={isMobileDevice == 'true' ? StylesModule.GridStyle2Mobile : StylesModule.GridStyle2}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                value={value}
                onChange={(newValue) => DayChange(newValue)}
                slots={{ day: Day }}
                displayWeekNumber={true}
                slotProps={{
                  day: {
                    selectedDay: value,
                  } as any,
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            sx={isMobileDevice == 'true' ? StylesModule.GridStyle2Mobile : StylesModule.GridStyle2}
          >
            <Box sx={StylesModule.BoxStyle2}>
              <div className={s.css_1}>
                <Typography sx={StylesModule.TypographyStyle1}>
                  Статистика с разбиением на дни-часы
                </Typography>
                <Typography sx={StylesModule.TypographyStyle2}>Начало недели</Typography>
                <Typography sx={StylesModule.TypographyStyle3}>{startDay}</Typography>
                <Typography sx={StylesModule.TypographyStyle2}>Конец недели</Typography>
                <Typography sx={StylesModule.TypographyStyle3}>{lastDay}</Typography>
                <Button
                  variant='contained'
                  disabled={startDay ? false : true}
                  sx={{ margin: '10px', fontSize: '12px', fontWeight: '400' }}
                  onClick={() => {
                    props.StatOptions();
                  }}
                >
                  Применить
                </Button>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
