import React from 'react';
import { useState, useEffect } from 'react';
import s from '../Styles/Pages/firstpage.module.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import api from '../api/api';
import techsuppLogo from '../Styles/img/technical_support.png';
import StatChartFirst from '../components/FirstPage/StatChartFirst';
import MinorDataCards from '../components/FirstPage/MinorDataCards';
import StatChartSecond from '../components/FirstPage/StatChartSecond';
import StreetLightGroupStatus from '../components/FirstPage/StreetLightGroupStatus';
import H24LastEvents from '../components/FirstPage/24HLastEvents';
import TempChart from '../components/FirstPage/TempChart';
import ProfileBlock from '../components/FirstPage/ProfileBlock';

type Groups = {
  id: number;
  postName: string;
  groupID: number;
  shirota: number;
  dolgota: number;
  last_value: number | string;
  last_update: Date;
  date: Date;
};

const FirstPage = () => {
  const [groups, setGroups] = useState<Groups[]>([]);
  const isMobileDevice = localStorage.getItem('isMobile');

  useEffect(() => {
    FetchApi();
  }, []);

  const FetchApi = async () => {
    // const groups = await api.dataGet();
    // console.log(groups);
    // const filtredGroups = groups.filter((group: Groups) => group.last_value !== null);
    // console.log(filtredGroups);
    // const correctedGroups = filtredGroups.map((group: Groups) => {
    //   return createData(
    //     group.id,
    //     group.postName,
    //     group.groupID,
    //     group.shirota,
    //     group.dolgota,
    //     Number(group.last_value) < 1 ? 'Выключен' : 'Включен',
    //     group.last_update,
    //     group.date,
    //   );
    // });
    // setGroups(correctedGroups);
  };

  const createData = (
    id: number,
    postName: string,
    groupID: number,
    shirota: number,
    dolgota: number,
    last_value: number | string,
    last_update: Date,
    date: Date,
  ) => {
    return {
      id,
      postName,
      groupID,
      shirota,
      dolgota,
      last_value,
      last_update,
      date,
    };
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: 'center',
    margin: 'auto !important',
    borderRadius: '8px',
    color: 'rgb(95, 116, 141)',
    fontSize: '13px',
    fontWeight: '600',
    fontFamily: 'Montserrat, sans-serif',
    boxShadow:
      'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
  }));

  return (
    <>
      <div>
        <div>
          <Box sx={{ width: 1 }}>
            <Box
              display={isMobileDevice == 'true' ? 'flex' : 'grid'}
              gridTemplateColumns='repeat(12, 1fr)'
              gap={2}
              sx={{ flexDirection: 'column' }}
            >
              {isMobileDevice == 'true' ? (
                <>
                  <Box gridColumn='span 4'>
                    <ProfileBlock />
                  </Box>
                  <Box gridColumn='span 8'>
                    <Box sx={{ width: '100%' }}>
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{
                          flexDirection: isMobileDevice == 'true' ? 'column' : 'row',
                          alignContent: isMobileDevice == 'true' ? 'center' : 'center',
                        }}
                      >
                        <MinorDataCards />
                        <Grid item xs={isMobileDevice == 'true' ? 12 : 6}>
                          <Item>
                            <StatChartSecond />
                          </Item>
                        </Grid>
                        <StreetLightGroupStatus />
                      </Grid>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box gridColumn='span 8'>
                    <Box sx={{ width: '100%' }}>
                      <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                        sx={{
                          flexDirection: isMobileDevice == 'true' ? 'column' : 'row',
                          alignContent: isMobileDevice == 'true' ? 'center' : 'center',
                        }}
                      >
                        <MinorDataCards />
                        <Grid item xs={isMobileDevice == 'true' ? 12 : 6}>
                          <Item>
                            <StatChartSecond />
                          </Item>
                        </Grid>
                        <StreetLightGroupStatus />
                      </Grid>
                    </Box>
                  </Box>
                  <Box gridColumn='span 4'>
                    <ProfileBlock />
                  </Box>
                </>
              )}

              <H24LastEvents />
              <Box gridColumn='span 4'>
                <Item>
                  <TempChart />
                </Item>
              </Box>
              <Box gridColumn='span 8'>
                <Item>
                  <StatChartFirst />
                </Item>
              </Box>
              <Box gridColumn='span 4'>
                <Item>
                  <img src={techsuppLogo} style={{ width: '80%' }}></img>
                </Item>
                <Item
                  sx={{
                    marginTop: '10px !important',
                    color: 'rgb(95, 116, 141)',
                    fontSize: '13px',
                    fontWeight: '600',
                    fontFamily: 'Montserrat, sans-serif',
                  }}
                >
                  Техническая поддержка
                </Item>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default FirstPage;
