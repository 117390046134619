import * as React from 'react';
import { useState, useEffect } from 'react';
import api from '../../api/api';
import Chart from 'react-apexcharts';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { GridColDef } from '@mui/x-data-grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: 'center',
  margin: 'auto !important',
  borderRadius: '8px',
  color: 'rgb(95, 116, 141)',
  fontSize: '13px',
  fontWeight: '600',
  fontFamily: 'Montserrat, sans-serif',
  boxShadow:
    'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
}));

const columns: GridColDef[] = [
  // { field: 'postName', headerName: 'Пост', flex: 1 },
  //   { field: 'groupID', headerName: 'Group ID', width: 90 },

  {
    field: 'text',
    headerName: 'Событие',
    flex: 1,
    // width: 600,
  },
  {
    field: 'date',
    headerName: 'Время',
    flex: 1,
    // width: 200,
  },
];

type resultData = {
  id: number;
  text: string;
  date: Date;
};

export default function H24LastEvents() {
  const [result, setResult] = useState<resultData[]>([]);

  useEffect(() => {
    FetchApi();
  }, []);

  const FetchApi = async () => {
    const response = await fetch('https://lumen.bikli.kz/Api/api/getLastNotifications.php', {
      method: 'GET',
    });
    const jsonData = await response.json();
    setResult(jsonData);
  };

  return (
    <>
      <Box gridColumn='span 8'>
        <Item>
          <Box
            sx={{
              width: '90%',
              display: 'inline-block',
              color: 'rgb(95, 116, 141)',
              fontSize: '13px',
              fontWeight: '600',
              fontFamily: 'Montserrat, sans-serif',
              margin: '2%',
            }}
          >
            События за последние 24 часа
            <DataGrid
              sx={{
                height: '230px',

                '& .MuiDataGrid-cellContent': {
                  whiteSpace: 'pre',
                },
              }}
              rows={result}
              columns={columns}
              getRowHeight={() => 'auto'}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              // checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </Item>
      </Box>
    </>
  );
}
