import * as React from 'react';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import NotificationPopper from '../Notifications/popper';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { setMenuOpen } from '../../redux/slices/AppControlSlice';

interface Props {}

const MobileButtons = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { Notifications } = useSelector((state: RootState) => state.WebSocket);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const LogOut = async () => {
    localStorage.removeItem('login');
    window.location.reload();
  };
  const menuOpen = async () => {
    dispatch(setMenuOpen(true));
  };
  const openNotificationPopper = Boolean(anchorEl);
  return (
    <>
      <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
        <IconButton aria-label='delete' size='large' onClick={menuOpen}>
          <MenuIcon color='info' />
        </IconButton>
        <IconButton
          aria-label='delete'
          size='large'
          onClick={() => {
            navigate('/notifications');
          }}
        >
          <HistoryEduIcon color='info' />
        </IconButton>
        <Badge color='secondary' badgeContent={Notifications.length ? Notifications.length : 0}>
          <IconButton
            aria-label='delete'
            size='large'
            onClick={handleClick}
            disabled={Notifications.length ? false : true}
          >
            <CircleNotificationsIcon color='info' />
          </IconButton>
        </Badge>

        <IconButton aria-label='delete' size='large' onClick={LogOut}>
          <LogoutIcon color='info' />
        </IconButton>
      </Stack>
      <NotificationPopper
        open={openNotificationPopper}
        anchorEl={anchorEl}
        WSNotifications={Notifications}
      />
    </>
  );
};

export default MobileButtons;
