import * as React from 'react';
import { useState, useEffect } from 'react';
import api from '../../api/api';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import roadLights1 from '../../Styles/img/street_lights_1.png';
import roadLights2 from '../../Styles/img/street_lights_2.png';
import streetlights1 from '../../Styles/img/street_light_1.png';
import streetlights2 from '../../Styles/img/street_light_2.png';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { setMinorCardBlockHeight } from '../../redux/slices/AppControlSlice';

type GroupsAndPosts = {
  post: postsUniq[];
  group: groupsUniq[];
};

type postsUniq = {
  postID: string;
};

type groupsUniq = {
  groupID: string;
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  margin: 'auto !important',
  borderRadius: '8px',
  boxShadow:
    'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
}));

export default function MinorDataCards() {
  const [UniqGroupsAndLines, setUniqGroupsAndLines] = useState<GroupsAndPosts>();
  const isMobileDevice = localStorage.getItem('isMobile');
  const divBlock = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    FetchApi();
    if (divBlock.current) {
      const width = divBlock.current.getBoundingClientRect().height;
      dispatch(setMinorCardBlockHeight(width));
    }
  }, []);

  const FetchApi = async () => {
    const UniqGroupsAndLines = await api.getUniqGroupsAndLines();
    setUniqGroupsAndLines(UniqGroupsAndLines);
  };

  return (
    <>
      <Grid item xs={isMobileDevice == 'true' ? 12 : 6}>
        <div ref={divBlock}>
          <Item>
            <Stack sx={{ justifyContent: 'center' }} direction='row' spacing={2}>
              <Item sx={{ boxShadow: 'none' }}>
                <Card sx={{ boxShadow: 'none', minWidth: 90 }}>
                  <CardContent>
                    <Typography
                      sx={{
                        color: 'rgb(95, 116, 141)',
                        fontSize: '13px',
                        fontWeight: '600',
                        fontFamily: 'Montserrat, sans-serif',
                      }}
                      color='text.secondary'
                      gutterBottom
                    >
                      Уличное освещение
                    </Typography>
                    <Typography
                      variant='h5'
                      component='div'
                      sx={{
                        color: '#23366bde',
                        fontSize: '14px',
                        fontWeight: '600',
                        fontFamily: 'Montserrat, sans-serif',
                      }}
                    >
                      Всего постов освещения
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '55px',
                        height: '55px',
                        borderRadius: '5px',
                        background: 'rgba(36, 153, 239, 0.08)',
                        margin: 'auto',
                        marginTop: '10px',
                      }}
                    >
                      <Typography variant='h4' sx={{ color: 'rgb(36, 153, 239)' }}>
                        {UniqGroupsAndLines ? UniqGroupsAndLines.post.length - 1 : 0}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button size='small'>Подробнее</Button>
                  </CardActions>
                </Card>
              </Item>
              <Item sx={{ boxShadow: 'none' }}>
                <div>
                  <img
                    src={roadLights1}
                    style={{ height: isMobileDevice == 'true' ? '45px' : '70px' }}
                  ></img>
                  <img
                    src={roadLights2}
                    style={{ height: isMobileDevice == 'true' ? '45px' : '70px' }}
                  ></img>
                </div>
              </Item>
            </Stack>
          </Item>
        </div>
      </Grid>
      <Grid item xs={isMobileDevice == 'true' ? 12 : 6}>
        <Item>
          <Stack sx={{ justifyContent: 'center' }} direction='row' spacing={2}>
            <Item sx={{ boxShadow: 'none' }}>
              <Card sx={{ boxShadow: 'none', minWidth: 90 }}>
                <CardContent>
                  <Typography
                    sx={{
                      color: 'rgb(95, 116, 141)',
                      fontSize: '13px',
                      fontWeight: '600',
                      fontFamily: 'Montserrat, sans-serif',
                    }}
                    color='text.secondary'
                    gutterBottom
                  >
                    Уличное освещение
                  </Typography>
                  <Typography
                    variant='h5'
                    component='div'
                    sx={{
                      color: '#23366bde',
                      fontSize: '14px',
                      fontWeight: '600',
                      fontFamily: 'Montserrat, sans-serif',
                    }}
                  >
                    Всего линий освещений
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '55px',
                      height: '55px',
                      borderRadius: '5px',
                      background: 'rgba(36, 153, 239, 0.08)',
                      margin: 'auto',
                      marginTop: '10px',
                    }}
                  >
                    <Typography variant='h4' sx={{ color: 'rgb(36, 153, 239)' }}>
                      {UniqGroupsAndLines ? UniqGroupsAndLines.group.length - 1 : 0}
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions>
                  <Button size='small'>Подробнее</Button>
                </CardActions>
              </Card>
            </Item>
            <Item sx={{ boxShadow: 'none' }}>
              <div>
                <img
                  src={streetlights1}
                  style={{ height: isMobileDevice == 'true' ? '45px' : '70px' }}
                ></img>
                <img
                  src={streetlights2}
                  style={{ height: isMobileDevice == 'true' ? '45px' : '70px' }}
                ></img>
              </div>
            </Item>
          </Stack>
        </Item>
      </Grid>
    </>
  );
}
