import React from 'react';
import TWTable from '../components/TWTable/table';
const Content = () => {
  return (
    <>
      <div className='MainPageBody'>
        <TWTable />
      </div>
    </>
  );
};

export default Content;
