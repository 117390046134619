import * as React from 'react';
import { useState, useEffect } from 'react';
import api from '../../api/api';
import Chart from 'react-apexcharts';

type StatDataAmpers = {
  series: { data: any }[];
};

interface ApexOptions {
  annotations?: ApexAnnotations;
  chart?: ApexChart;
  colors?: any[];
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  forecastDataPoints?: ApexForecastDataPoints;
  grid?: ApexGrid;
  labels?: string[];
  legend?: ApexLegend;
  markers?: ApexMarkers;
  noData?: ApexNoData;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
  states?: ApexStates;
  stroke?: ApexStroke;
  subtitle?: ApexTitleSubtitle;
  theme?: ApexTheme;
  title?: ApexTitleSubtitle;
  tooltip?: ApexTooltip;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis | ApexYAxis[];
}

const options1: ApexOptions = {
  chart: {
    id: 'chart2',
    type: 'area',
    height: 230,
    foreColor: '#ccc',
    toolbar: {
      autoSelected: 'pan',
      show: true,
    },
  },
  colors: ['#00BAEC'],
  stroke: {
    width: 3,
  },
  grid: {
    borderColor: '#555',
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    gradient: {
      opacityFrom: 0.55,
      opacityTo: 0,
    },
  },
  // markers: {
  //   size: 5,
  //   colors: ['#000524'],
  //   strokeWidth: 3,
  // },
  tooltip: {
    theme: 'dark',
    x: {
      format: 'dd/MM/yy HH:mm',
    },
  },
  xaxis: {
    type: 'datetime',
  },

  yaxis: {
    min: 0,
    floating: false,
    decimalsInFloat: 1,
    tickAmount: 4,
    //   labels: {
    //     maxWidth: 10,
    //   },
  },
};

var options2: ApexOptions = {
  chart: {
    id: 'chart1',
    height: 130,
    type: 'bar',
    foreColor: '#ccc',
    brush: {
      target: 'chart2',
      enabled: true,
    },
    selection: {
      enabled: true,
      fill: {
        color: '#fff',
        opacity: 0.4,
      },
    },
  },
  colors: ['#FF0080'],
  stroke: {
    width: 2,
  },
  grid: {
    borderColor: '#444',
  },
  markers: {
    size: 0,
  },
  xaxis: {
    type: 'datetime',
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    floating: false,
    decimalsInFloat: 1,
    tickAmount: 2,
  },
};
export default function StatChartFirst() {
  const [StatDataAmpers, setStatDataAmpers] = useState<StatDataAmpers>({ series: [] });

  useEffect(() => {
    FetchApi();
  }, []);

  const FetchApi = async () => {
    const ampersPostGroup = {
      postID: 'KND1',
      groupID: '1.A',
    };
    const getAndFormateAmpers = await api.getAndFormateAmpers(ampersPostGroup);
    //   console.log(getAndFormateAmpers);

    const getAndFormateAmpersSeries = {
      series: [
        {
          data: getAndFormateAmpers,
        },
      ],
    };

    setStatDataAmpers(getAndFormateAmpersSeries);
  };

  return (
    <>
      <Chart options={options1} series={StatDataAmpers.series} type='area' height={'230px'} />
      <Chart options={options2} series={StatDataAmpers.series} type='bar' height={'230px'} />
    </>
  );
}
