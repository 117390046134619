import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

type resultData = {
  id: number;
  T: number;
  W: number;
  Position: string;
  date: Date;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: '№', width: 90 },

  {
    field: 'T',
    headerName: 'Температура',
    width: 150,
  },
  {
    field: 'W',
    headerName: 'Влажность',
    width: 150,
  },
  {
    field: 'Position',
    headerName: 'Позиция',
    width: 150,
  },
  {
    field: 'date',
    headerName: 'Дата добавления',
    width: 200,
  },
];

const FetchApiAdd = async () => {
  const response = await fetch('http://lumen.bikli.kz/Api/api/table_add_test.php?var=17', {
    method: 'GET',
  });
  const json = response.json();
  return json;
};
// 51.1801, 71.446
export default function TWTable() {
  console.log('123123');
  const [result, setResult] = useState<resultData[]>([]);

  useEffect(() => {
    const FetchApi = async () => {
      const response = await fetch('https://lumen.bikli.kz/Api/api/TWGet.php', {
        method: 'GET',
      });
      const jsonData = await response.json();
      setResult(jsonData);
    };

    FetchApi();
  }, []);
  return (
    <>
      <Box sx={{ height: 800, width: '100%', background: '#fff', borderRadius: '10px' }}>
        <DataGrid
          rows={result}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[5]}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </>
  );
}
