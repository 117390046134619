import React, { CSSProperties, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import api from '../../api/api';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

import StylesModule from './UsersConnectStyles';

interface Props {
  postID: string;
  handleChangeTo: (arg0: number) => void;
}

type SortedUsersArr = {
  [x: string]: any;
  id: number;
  name: string;
};

type SortedUsers = {
  id: number;
  name: string;
};

const UsersConnect = (props: Props) => {
  const [checked, setChecked] = React.useState<SortedUsersArr[]>([]);
  const [UserAll, setUserAll] = React.useState([]);
  const [changeCheck, setChangeCheck] = React.useState(false);
  const [UsersSortedToggle, setUsersSortedToggle] = React.useState(false);
  const [UsersPostAccess, setUsersPostAccess] = React.useState([]);
  const [UsersWithAccess, setUsersWithAccess] = React.useState<SortedUsersArr[]>([]);
  const [UsersWithoutAccess, setUsersWithoutAccess] = React.useState<SortedUsersArr[]>([]);
  const leftChecked = intersection(checked, UsersWithAccess);
  const rightChecked = intersection(checked, UsersWithoutAccess);
  const navigate = useNavigate();

  useEffect(() => {
    FetchApiUsers();
  }, []);

  const FetchApiUsers = async () => {
    const res1 = await api.getUserAll();
    console.log(res1);
    setUserAll(res1);

    const data = {
      post: props.postID,
    };

    const res2 = await api.getUsersPostAccess(data);
    console.log(res2);
    setUsersPostAccess(res2);

    filterUsers(res1, res2);
  };

  const FetchApiAccessChange = async () => {
    const subUsersData: { id: number }[] = [];
    const subPostdata: { post: string }[] = [];
    UsersWithAccess.map((row) => subUsersData.push(objFetchGenerate(row.id)));
    subPostdata.push({ post: props.postID });
    const data = [];
    data.push(subUsersData);
    data.push(subPostdata);
    const res = await api.usersPostAccessChange(data);
    if (res) props.handleChangeTo(1);
  };

  const filterUsers = (UserAll: any[], UsersPostAccess: any) => {
    console.log(UserAll.length);
    if (UserAll.length > 0) {
      console.log(UserAll);
      console.log(UserAll);
      console.log(UsersPostAccess);

      sortUsers(UserAll, UsersPostAccess);
    }
  };

  const sortUsers = (
    UserAll: any[],
    UsersPostAccess: {
      userID: number;
      last_name: string;
      first_name: string;
      father_name: string;
    }[],
  ) => {
    let UsersWithAccess: SortedUsersArr[] = [];
    let UsersWithoutAccess: SortedUsersArr[] = [];

    UserAll.map(
      (g_user: { id: number; last_name: string; first_name: string; father_name: string }) => {
        let checkIN = 0;
        UsersPostAccess.map(
          (a_user: {
            userID: number;
            last_name: string;
            first_name: string;
            father_name: string;
          }) => {
            g_user.id == a_user.userID ? (checkIN = 1) : null;
          },
        );

        checkIN == 1
          ? UsersWithAccess.push(objGenerate(g_user))
          : UsersWithoutAccess.push(objGenerate(g_user));
      },
    );

    setUsersWithAccess(UsersWithAccess);
    setUsersWithoutAccess(UsersWithoutAccess);
    setUsersSortedToggle(true);
    console.log('checked');
    console.log(UsersWithAccess);
    console.log(UsersWithoutAccess);
  };

  const objGenerate = (user: {
    id: number;
    last_name: string;
    first_name: string;
    father_name: string;
  }) => {
    const data = {
      id: user.id,
      name: user.last_name + ' ' + user.first_name + ' ' + user.father_name,
    };

    return data;
  };

  const objFetchGenerate = (id: number) => {
    const data = {
      id: id,
    };

    return data;
  };

  function not(a: SortedUsersArr[], b: SortedUsersArr[]) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a: SortedUsersArr[], b: SortedUsersArr[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }
  const handleToggle = (value: { [x: string]: any; id: number; name: string }) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setUsersWithoutAccess(UsersWithoutAccess.concat(UsersWithAccess));
    setUsersWithAccess([]);
    setChangeCheck(true);
  };

  const handleCheckedRight = () => {
    setUsersWithoutAccess(UsersWithoutAccess.concat(leftChecked));
    setUsersWithAccess(not(UsersWithAccess, leftChecked));
    setChecked(not(checked, leftChecked));
    setChangeCheck(true);
  };

  const handleCheckedLeft = () => {
    setUsersWithAccess(UsersWithAccess.concat(rightChecked));
    setUsersWithoutAccess(not(UsersWithoutAccess, rightChecked));
    setChecked(not(checked, rightChecked));
    setChangeCheck(true);
  };

  const handleAllLeft = () => {
    setUsersWithAccess(UsersWithAccess.concat(UsersWithoutAccess));
    setUsersWithoutAccess([]);
    setChangeCheck(true);
  };

  const customList = (items: SortedUsersArr[] | [], title: string) => {
    console.log(items);
    return (
      <Paper sx={{ width: 250, height: 430, overflow: 'auto' }}>
        <Box sx={StylesModule.Title}>{title}</Box>
        {title == 'Пользователи без доступа к посту' ? (
          <Box
            sx={StylesModule.AddUser}
            onClick={() => {
              navigate('/add-user');
            }}
          >
            Добавить пользователя
          </Box>
        ) : null}
        <List dense component='div' role='list'>
          {items.map((value: SortedUsers) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItem key={value.id} role='listitem' button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    //   checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value.name}`} />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    );
  };

  return (
    <>
      <Button
        variant='contained'
        sx={StylesModule.BoxesHeadButtonStyle}
        disabled={!changeCheck}
        onClick={FetchApiAccessChange}
      >
        Принять изменения
      </Button>
      <Grid container spacing={2} justifyContent='center' alignItems='center'>
        {UsersSortedToggle ? (
          <>
            <Grid item>{customList(UsersWithAccess, 'Пользователи с доступом к посту')}</Grid>
            <Grid item>
              <Grid container direction='column' alignItems='center'>
                <Button
                  sx={{ my: 0.5 }}
                  variant='outlined'
                  size='small'
                  onClick={handleAllRight}
                  disabled={UsersWithAccess.length === 0}
                  aria-label='move all right'
                >
                  ≫
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant='outlined'
                  size='small'
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label='move selected right'
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant='outlined'
                  size='small'
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label='move selected left'
                >
                  &lt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant='outlined'
                  size='small'
                  onClick={handleAllLeft}
                  disabled={UsersWithoutAccess.length === 0}
                  aria-label='move all left'
                >
                  ≪
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList(UsersWithoutAccess, 'Пользователи без доступа к посту')}</Grid>{' '}
          </>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </>
  );
};

export default UsersConnect;
