import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import StylesModyle from '../Styles/Pages/UsersList';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CommonItemsWrapper from '../components/DataTable/CommonItemsWrapper';
import api from '../api/api';
import s from '../Styles/Pages/UserList.module.css';
import FeedIcon from '@mui/icons-material/Feed';
type users = {
  id: number;
  fio: string;
  img: string;
  email: string;
};
const UsersList = () => {
  const [rows, setrows] = React.useState<users[]>([]);
  const [userdata, setuserdata] = React.useState<users>();

  useEffect(() => {
    FetchApi();
  }, []);

  const FetchApi = async () => {
    const resp = await api.getUserAll();

    let arr: users[] = [];
    resp.map(
      (data: {
        id: number;
        last_name: string;
        first_name: string;
        father_name: string;
        img: string;
        email: string;
      }) => {
        const row = createDataRow(
          data.id,
          data.last_name + ' ' + data.first_name + ' ' + data.father_name,
          data.img ? data.img : 'avatar.png',
          data.email,
        );
        arr.push(row);
      },
    );
    setrows(arr);
    console.log(arr);
  };

  function createDataRow(id: number, fio: string, img: string, email: string) {
    return { id, fio, img, email };
  }

  function createDataCol(
    title: string,
    data: string,
    navigate_to: string,
    align: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined,
  ) {
    return { title, data, navigate_to, align };
  }

  const columns = [
    createDataCol('#', 'id', 'none', 'left'),
    createDataCol('', 'img', 'none', 'left'),
    createDataCol('Пользователь', 'fio', 'func', 'left'),
    createDataCol('Почта', 'email', 'none', 'center'),
  ];

  const return_data = (data: any) => {
    console.log(data);
    setuserdata(data);
  };
  return (
    <>
      <Box sx={StylesModyle.Box_1}>
        <Grid container sx={StylesModyle.Grid}>
          <Grid sx={StylesModyle.Grid_1}>
            <Paper sx={StylesModyle.Paper_1}>
              <Box sx={StylesModyle.BoxesSubHeadStyle}>
                <Paper
                  component='form'
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                >
                  <IconButton sx={{ p: '10px' }} aria-label='menu'>
                    <MenuIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder='Поиск пользователя - введите фамилию'
                    inputProps={{ 'aria-label': 'Поиск пользователя - введите фамилию' }}
                  />
                  <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
                </Paper>
              </Box>
              <CommonItemsWrapper rows={rows} columns={columns} return_data={return_data} />
            </Paper>
          </Grid>
          {userdata ? (
            <Grid sx={StylesModyle.Grid_2}>
              <Paper sx={StylesModyle.Paper_2}>
                <Button sx={StylesModyle.Button} variant='contained'>
                  Перейти
                </Button>
                <div className={s.css_1}>
                  <Avatar alt='Remy Sharp' src={userdata?.img} sx={StylesModyle.Avatar} />
                  <Box sx={StylesModyle.BoxType}>{userdata?.fio}</Box>
                  <Box sx={{ width: '90%', height: '50%' }}>
                    <Paper sx={StylesModyle.Paper_3}>
                      <div className={s.css_2}>
                        <FeedIcon sx={StylesModyle.BoxIconStyle} />
                        <Typography sx={StylesModyle.BoxTypeStyle}>{userdata?.email}</Typography>
                      </div>
                      <div className={s.css_2}>
                        <FeedIcon sx={StylesModyle.BoxIconStyle} />
                        <Typography sx={StylesModyle.BoxTypeStyle}>-----</Typography>
                      </div>
                      <div className={s.css_2}>
                        <FeedIcon sx={StylesModyle.BoxIconStyle} />
                        <Typography sx={StylesModyle.BoxTypeStyle}>-----</Typography>
                      </div>
                      <div className={s.css_2}>
                        <FeedIcon sx={StylesModyle.BoxIconStyle} />
                        <Typography sx={StylesModyle.BoxTypeStyle}>-----</Typography>
                      </div>
                      <div className={s.css_2}>
                        <FeedIcon sx={StylesModyle.BoxIconStyle} />
                        <Typography sx={StylesModyle.BoxTypeStyle}>-----</Typography>
                      </div>
                    </Paper>
                  </Box>
                </div>
              </Paper>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </>
  );
};

export default UsersList;
