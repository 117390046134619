import * as React from 'react';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import NotificationPopper from '../Notifications/popper';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/rootReducer';

interface Props {}

const PCButtons = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { Notifications } = useSelector((state: RootState) => state.WebSocket);
  const isMobile = localStorage.getItem('isMobile');

  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const LogOut = async () => {
    localStorage.removeItem('login');
    window.location.reload();
  };

  const openNotificationPopper = Boolean(anchorEl);
  return (
    <>
      <Button
        variant='contained'
        // disabled={true}
        sx={{
          height: '30px',
          width: '150px',
          margin: 'auto',
          backgroundColor: '#2499ef',
        }}
        onClick={() => {
          navigate('/notifications');
        }}
      >
        История
      </Button>{' '}
      <Badge
        color='secondary'
        badgeContent={Notifications.length ? Notifications.length : 0}
        sx={{
          margin: 'auto',
        }}
      >
        <Button
          variant='contained'
          disabled={Notifications.length ? false : true}
          sx={{
            height: '30px',
            width: '150px',
            margin: 'auto',
            backgroundColor: '#2499ef',
            border: !Notifications.length ? 'none' : '1px solid #446fb1',
          }}
          onClick={handleClick}
        >
          Оповещения
        </Button>
      </Badge>
      <NotificationPopper
        open={openNotificationPopper}
        anchorEl={anchorEl}
        WSNotifications={Notifications}
      />
      <Button
        variant='contained'
        disabled={true}
        sx={{
          height: '30px',
          width: '150px',
          margin: 'auto',
          backgroundColor: '#2499ef',
          //
        }}
      ></Button>{' '}
      <Button
        variant='contained'
        // disabled={true}
        sx={{
          height: '30px',
          width: '150px',
          margin: 'auto',
          backgroundColor: '#2499ef',
        }}
        onClick={LogOut}
      >
        Выйти
      </Button>
    </>
  );
};

export default PCButtons;
