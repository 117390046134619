import { combineReducers } from '@reduxjs/toolkit';

import MapControlSliceReducer from './slices/MapControlSlice';
import WebSocketStore from './slices/WebSocketStore';
import AppControlSlice from './slices/AppControlSlice';
const rootReducer = combineReducers({
  MapControl: MapControlSliceReducer,
  WebSocket: WebSocketStore,
  AppControl: AppControlSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
