import React, { CSSProperties, useEffect } from 'react';

const StylesModule = {
  GridContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row wrap',
    marginTop: '-24px',
    width: 'calc(100% + 24px)',
    marginLeft: '-24px',
  },

  GridContainerMobile: {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row wrap',
    marginTop: '-24px',
    width: 'calc(100% + 24px)',
    marginLeft: '-24px',
    flexDirection: 'column-reverse',
  },

  GridItem1: {
    paddingLeft: '24px',
    paddingTop: '24px',
    flexBasis: '75%',
    flexGrow: '0',
    maxWidth: '75%',
  },

  GridItem1Mobile: {
    paddingLeft: '24px',
    paddingTop: '24px',
    flexBasis: '100%',
    flexGrow: '0',
    maxWidth: '100%',
  },

  GridItem2: {
    paddingLeft: '24px',
    paddingTop: '24px',
    flexBasis: '25%',
    flexGrow: '0',
    maxWidth: '25%',
  },

  GridItem2Mobile: {
    paddingLeft: '24px',
    paddingTop: '24px',
    flexBasis: '100%',
    flexGrow: '0',
    maxWidth: '100%',
  },

  Paper1: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(18, 31, 67)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundImage: 'none',
    overflow: 'hidden',
    boxShadow:
      'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
    borderRadius: '8px',
    padding: '24px',
    position: 'relative',
  },

  Paper3: {
    backgroundColor: '#f3f4f900',
    color: 'rgb(18, 31, 67)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundImage: 'none',
    overflow: 'hidden',
    boxShadow:
      'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
    borderRadius: '8px',
    position: 'relative',
    marginTop: '24px',
  },

  Paper2: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(18, 31, 67)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundImage: 'none',
    overflow: 'hidden',
    boxShadow:
      'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
    borderRadius: '8px',
    height: '75vh',
  },

  Box1: {
    top: '0px',
    left: '0px',
    height: '125px',
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
    backgroundColor: 'rgb(243, 244, 249)',
  },

  Box2: {
    zIndex: '1',
    marginTop: '55px',
    position: 'relative',
  },

  Box3: {
    display: 'flex',
    justifyContent: 'center',
  },

  Box4: {
    marginTop: '16px',
  },
  Box5: {
    textAlign: 'center',
    fontFamily: 'Montserrat,sans-serif',
    fontWeight: '550',
    fontSize: '19px',
    lineHeight: '1.5',
    color: '#51555b',
  },

  Box6: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '360px',
    flexWrap: 'wrap',
    margin: '8px auto auto',
  },

  Box7: {
    display: 'flex',
    alignItems: 'center',
    width: '110px',
    justifyContent: 'center',
    fontFamily: 'Montserrat,sans-serif',
    fontWeight: '550',
    fontSize: '15px',
    lineHeight: '1.5',
    letterSpacing: '1px',
    color: 'rgb(95 116 141 / 77%)',
  },

  AvatarStyle: {
    width: '100px',
    height: '100px',
    margin: 'auto',
    overflow: 'hidden',
    borderRadius: '50%',
    paddingTop: '12px',
    backgroundColor: 'rgb(224 224 224)',
    border: '3px solid #2499ef',
  },
};

export default StylesModule;
