import React from 'react';
import Groups from '../components/DataTable/Groups';
const Content = () => {
  return (
    <>
      <Groups />
    </>
  );
};

export default Content;
