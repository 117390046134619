import React, { CSSProperties } from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import api from '../api/api';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import s from '../Styles/BoxPage/Boxes.module.css';
import Avatar from '@mui/material/Avatar';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import { useNavigate } from 'react-router-dom';

type Groups = {
  id: number;
  postName: string;
  groupID: number;
  shirota: number;
  dolgota: number;
  last_value: number | string;
  last_update: Date;
  date: Date;
};

const BoxesPaperStyle: CSSProperties = {
  background: 'rgb(255, 255, 255)',
  color: '#23366bde',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  backgroundImage: 'none',
  boxShadow:
    'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
  borderRadius: '8px',
  padding: '16px 24px',
};

const BoxesHeadStyle: CSSProperties = {
  gap: 'gap',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const BoxesHeadStyle2: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const BoxesHeadStyle3: CSSProperties = {
  width: '40px',
  height: '40px',
  display: 'flex',
  borderRadius: '5px',
  alignItems: 'center',
  marginRight: '0.5rem',
  justifyContent: 'center',
  backgroundColor: 'rgb(229, 243, 253)',
};

const BoxesHeadIconStyle: CSSProperties = {
  userSelect: 'none',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  fill: 'currentcolor',
  flexShrink: '0',
  transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  fontSize: '1.5rem',
  color: 'rgb(36, 153, 239)',
};

const BoxesHeadIconStyle2: CSSProperties = {
  userSelect: 'none',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  fill: 'currentcolor',
  flexShrink: '0',
  transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  color: 'rgb(140, 163, 186)',
  fontSize: '16px',
};

const BoxesHeadButtonStyle: CSSProperties = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  WebkitTapHighlightColor: 'transparent',
  outline: '0px',
  border: '0px',
  margin: '0px',
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  appearance: 'none',
  textDecoration: 'none',
  fontFamily: 'Montserrat, sans-serif',
  lineHeight: '1.75',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  backgroundColor: '#2499ef',
  textTransform: 'none',
  borderRadius: '4px',
  boxShadow: 'none',
  padding: '0.6rem 1.5rem',
  fontSize: '12px',
  fontWeight: '600',
  minWidth: '84px',
  color: 'white',
};

const BoxesSubHeadStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
  flexWrap: 'wrap',
  margin: '24px 0px',
};

const BoxesHeadTypeStyle: CSSProperties = {
  color: '#23366bde',
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '14px',
  lineHeight: '1',
  fontWeight: '600',
};

const BoxesHeadTypeStyle3: CSSProperties = {
  fontSize: '13px',
  fontWeight: '600',
  color: '#23366bde',
  fontFamily: 'Montserrat, sans-serif',
  lineHeight: '1.5',
};

const BoxesHeadTypeStyle4: CSSProperties = {
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '1.65',
  color: 'rgb(95, 116, 141)',
  fontFamily: 'Montserrat, sans-serif',
};

const BoxesHeadTypeStyle5: CSSProperties = {
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '1.65',
  color: 'rgb(95, 116, 141)',
  fontFamily: 'Montserrat, sans-serif',
  margin: '0px 0px 0px 8px',
};

const BoxesHeadTypeStyle2: CSSProperties = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  WebkitTapHighlightColor: 'transparent',
  backgroundColor: 'transparent',
  outline: '0px',
  border: '0px',
  margin: '0px 1rem',
  borderRadius: '0px',
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  appearance: 'none',
  textDecoration: 'none',
  fontFamily: 'Montserrat, sans-serif',
  lineHeight: '1.25',
  maxWidth: '360px',
  position: 'relative',
  minHeight: '48px',
  flexShrink: '0',
  overflow: 'hidden',
  whiteSpace: 'normal',
  textAlign: 'center',
  flexDirection: 'column',
  color: 'rgb(95, 116, 141)',
  textTransform: 'none',
  fontSize: '12px',
  fontWeight: '500',
  padding: '0px 8px',
  minWidth: 'auto',
};

const BoxesGridStyle: CSSProperties = {
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'row wrap',
  marginTop: '-24px',
  width: 'calc(100% + 24px)',
  marginLeft: '-24px',
};

const MobileBoxesGridStyle: CSSProperties = {
  boxSizing: 'border-box',
  margin: '0px',
  flexDirection: 'row',
  flexBasis: '100%',
  flexGrow: '0',
  maxWidth: '100%',
  paddingLeft: '24px',
  paddingTop: '24px',
};

const BoxesGridStyle2: CSSProperties = {
  paddingLeft: '24px',
  paddingTop: '24px',
  flexBasis: '25%',
  flexGrow: '0',
  maxWidth: '25%',
};

const BoxesBoxStyle1: CSSProperties = {
  padding: '16px',
  border: '1px solid rgb(211, 230, 243)',
  borderRadius: '4px',
  cursor: 'pointer',
};

const BoxesBoxStyle2: CSSProperties = {
  margin: '0px 0px 0px 16px',
};

const a = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

const Boxes = () => {
  const [value, setValue] = React.useState(0);
  const isMobileDevice = localStorage.getItem('isMobile');
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <Box mt={2} mb={2}>
        <Paper sx={BoxesPaperStyle}>
          <Box sx={BoxesHeadStyle}>
            <Box sx={BoxesHeadStyle2}>
              <Box sx={BoxesHeadStyle3}>
                <AllInboxIcon sx={BoxesHeadIconStyle} />
              </Box>
              <Typography variant='h5' gutterBottom sx={BoxesHeadTypeStyle}>
                ШУНО
              </Typography>
            </Box>

            <Button variant='contained' sx={BoxesHeadButtonStyle}>
              Добавить ШУНО
            </Button>

            <Tabs value={value} onChange={handleChange} variant='scrollable' scrollButtons='auto'>
              <Tab sx={BoxesHeadTypeStyle2} label='Все' {...a11yProps(0)} />
              <Tab sx={BoxesHeadTypeStyle2} label='Открытые' {...a11yProps(1)} />
              <Tab sx={BoxesHeadTypeStyle2} label='Закрытые' {...a11yProps(2)} />
              <Tab sx={BoxesHeadTypeStyle2} label='Резервные' {...a11yProps(2)} />
            </Tabs>
          </Box>
          <Box sx={BoxesSubHeadStyle}>
            <Paper
              component='form'
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
            >
              <IconButton sx={{ p: '10px' }} aria-label='menu'>
                <MenuIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder='Поиск ШУНО - введите номер'
                inputProps={{ 'aria-label': 'Поиск ШУНО - введите номер' }}
              />
              <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
            </Paper>
          </Box>
          <Grid container sx={BoxesGridStyle}>
            {a.map((i) => {
              return (
                <Grid sx={isMobileDevice == 'true' ? MobileBoxesGridStyle : BoxesGridStyle2}>
                  <Box sx={BoxesBoxStyle1} onClick={() => navigate('/box/77777')}>
                    <div className={s.css_1}>
                      <Avatar sx={{ bgcolor: 'rgb(229, 243, 253)' }}>
                        <AllInboxIcon sx={BoxesHeadIconStyle} />
                      </Avatar>
                      <Box sx={BoxesBoxStyle2}>
                        <Typography variant='h6' gutterBottom sx={BoxesHeadTypeStyle3}>
                          ШУНО #456651324
                        </Typography>
                        <Typography variant='h6' gutterBottom sx={BoxesHeadTypeStyle4}>
                          Закрыт
                        </Typography>
                      </Box>
                    </div>
                    <div className={s.css_2}>
                      <OfflineBoltIcon sx={BoxesHeadIconStyle2} />
                      <Typography sx={BoxesHeadTypeStyle5}>Закрыт</Typography>
                    </div>
                    <div className={s.css_2}>
                      <OfflineBoltIcon sx={BoxesHeadIconStyle2} />
                      <Typography sx={BoxesHeadTypeStyle5}>Закрыт</Typography>
                    </div>
                    <div className={s.css_2}>
                      <OfflineBoltIcon sx={BoxesHeadIconStyle2} />
                      <Typography sx={BoxesHeadTypeStyle5}>Закрыт</Typography>
                    </div>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default Boxes;
