import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

type resultData = {
  id: number;
  text: string;
  date: Date;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: '№', width: 90 },
  //   { field: 'groupID', headerName: 'Group ID', width: 90 },

  {
    field: 'text',
    headerName: 'Событие',
    // flex: 1,
    width: 600,
  },
  {
    field: 'date',
    headerName: 'Дата события',
    // flex: 1,
    width: 200,
  },
];

export default function NotificationsTable() {
  const [result, setResult] = useState<resultData[]>([]);

  useEffect(() => {
    const FetchApi = async () => {
      const response = await fetch('https://lumen.bikli.kz/Api/api/getNotifications.php', {
        method: 'GET',
      });
      const jsonData = await response.json();
      setResult(jsonData);
    };

    FetchApi();
  }, []);
  return (
    <>
      <Box sx={{ height: 800, backgroundColor: '#fff' }}>
        <DataGrid
          rows={result}
          columns={columns}
          getRowHeight={() => 'auto'}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 35,
              },
            },
          }}
          pageSizeOptions={[5]}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </>
  );
}
