import React, { CSSProperties } from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import api from '../api/api';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import s from '../Styles/BoxPage/BoxDetails.module.css';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Chip from '@mui/material/Chip';
const itemData = [
  {
    img: 'https://img02.flagma.kz/photo/shkafy-upravleniya-naruzhnym-osveshcheniem-shuno-2158167_medium.jpg',
    title: 'ШУНО 1',
  },
  {
    img: 'https://img02.flagma.kz/photo/shkaf-raspredelitelny-silovoy-shrs-podrobnee-kzg8112148-shkafnoeschit-3024413_medium.jpg',
    title: 'ШУНО 2',
  },
  {
    img: 'https://img02.flagma.kz/photo/shkafy-upravleniya-400a-s-novymi-avtomatami-na-40a-3093171_medium.jpg',
    title: 'ШУНО 3',
  },
  {
    img: 'https://img01.flagma.kz/photo/shkafy-upravleniya-400a-s-novymi-avtomatami-na-40a-3093172_big.jpg',
    title: 'ШУНО 4',
  },
  {
    img: 'https://img02.flagma.kz/photo/shkafy-upravleniya-400a-s-novymi-avtomatami-na-40a-3093173_big.jpg',
    title: 'ШУНО 5',
  },
  {
    img: 'https://img02.flagma.kz/photo/shkafy-upravleniya-400a-s-novymi-avtomatami-na-40a-3093174_big.jpg',
    title: 'ШУНО 6',
  },
  {
    img: 'https://img01.flagma.kz/photo/shkafy-upravleniya-ekskavatora-esh-10-60-bu-2960172_big.jpg',
    title: 'ШУНО 7',
  },
  {
    img: 'https://img01.flagma.kz/photo/shkafy-upravleniya-ekskavatora-esh-10-60-bu-2960173_big.jpg',
    title: 'ШУНО 8',
  },
  {
    img: 'https://img01.flagma.kz/photo/shkafy-upravleniya-ekskavatora-esh-10-60-b-u-2913633_big.jpg',
    title: 'ШУНО 9',
  },
  {
    img: 'https://img01.flagma.kz/photo/shkafy-upravleniya-ekskavatora-esh-10-60-b-u-2913634_big.jpg',
    title: 'ШУНО 10',
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const BoxesGridStyle: CSSProperties = {
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'row wrap',
  marginTop: '-24px',
  width: 'calc(100% + 24px)',
  marginLeft: '-24px',
};

const BoxesGridContainerStyle: CSSProperties = {
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'row wrap',
  marginTop: '-24px',
  width: 'calc(100% + 24px)',
  marginLeft: '-24px',
  flexDirection: 'column',
  alignContent: 'center',
};

const BoxesGridStyle2: CSSProperties = {
  paddingLeft: '24px',
  paddingTop: '24px',
};

const BoxesGridStyle8: CSSProperties = {
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'row wrap',
  marginTop: '-24px',
  width: 'calc(100% + 24px)',
  marginLeft: '-24px',
};

const BoxesGridStyle6: CSSProperties = {
  paddingLeft: '24px',
  paddingTop: '24px',
  width: 'inherit',
};

const BoxesGridStyle5: CSSProperties = {
  flexBasis: '41.6667%',
  flexGrow: '0',
  maxWidth: '41.6667%',
  paddingLeft: '24px',
  paddingTop: '24px',
  textAlign: 'left',
};

const BoxesChipStyle: CSSProperties = {
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '0.8125rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  transition:
    'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  cursor: 'default',
  outline: '0px',
  textDecoration: 'none',
  border: '0px',
  padding: '0px',
  verticalAlign: 'middle',
  boxSizing: 'border-box',
  height: '25px',
  borderRadius: '4px',
  color: 'rgb(255, 255, 255)',
  backgroundColor: 'rgb(39, 206, 136)',
};

const BoxesGridStyle4: CSSProperties = {
  paddingLeft: '24px',
  paddingTop: '24px',
  flexBasis: '58.3333%',
  flexGrow: '0',
  maxWidth: '58.3333%',
};

const BoxesGridStyle7: CSSProperties = {
  paddingLeft: '24px',
  paddingTop: '24px',
  flexBasis: '100%',
  flexGrow: '0',
  maxWidth: '100%',
};

const BoxesGridStyle3: CSSProperties = {
  width: '40px',
  height: '40px',
  display: 'flex',
  borderRadius: '5px',
  alignItems: 'center',
  marginRight: '0.5rem',
  justifyContent: 'center',
  backgroundColor: 'rgb(229, 243, 253)',
};

const BoxesIconStyle1: CSSProperties = {
  userSelect: 'none',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  fill: 'currentcolor',
  flexShrink: '0',
  transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  fontSize: '1.5rem',
  color: 'rgb(36, 153, 239)',
};

const BoxesTypographyStyle1: CSSProperties = {
  fontSize: '14px',
  lineHeight: '1',
  fontWeight: '600',
  color: '#23366bde',
  fontFamily: 'Montserrat, sans-serif',
};

const BoxesTypographyStyle2: CSSProperties = {
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '1.65',
  color: 'rgb(95, 116, 141)',
  marginTop: '16px',
  fontFamily: 'Montserrat, sans-serif',
};

const BoxesTypographyStyle3: CSSProperties = {
  fontSize: '24px',
  fontWeight: '600',
  color: '#23366bde',
  fontFamily: 'Montserrat, sans-serif',
};

const BoxesTypographyStyle4: CSSProperties = {
  fontSize: '14px',
  lineHeight: '1',
  fontWeight: '600',
  color: '#23366bde',
  fontFamily: 'Montserrat, sans-serif',
};

const BoxesTypographyStyle6: CSSProperties = {
  fontSize: '14px',
  lineHeight: '1',
  fontWeight: '600',
  color: '#23366bde',
  fontFamily: 'Montserrat, sans-serif',
  marginTop: '24px',
};

const BoxesBoxStyle3: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
};

const BoxesBoxStyle4: CSSProperties = {
  paddingTop: '8px',
  paddingLeft: '16px',
  borderBottom: '1px solid rgb(211, 230, 243)',
  borderTopColor: 'rgb(211, 230, 243)',
  borderRightColor: 'rgb(211, 230, 243)',
  borderLeftColor: 'rgb(211, 230, 243)',
  backgroundColor: 'rgb(211, 230, 243)',
};

const BoxesPaperStyle1: CSSProperties = {
  backgroundColor: 'rgb(255, 255, 255)',
  color: '#23366bde',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  backgroundImage: 'none',
  overflow: 'hidden',
  boxShadow:
    'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
  borderRadius: '8px',
  padding: '16px',
};

const BoxesPaperStyle2: CSSProperties = {
  backgroundColor: 'rgb(255, 255, 255)',
  color: '#23366bde',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  backgroundImage: 'none',
  overflow: 'hidden',
  boxShadow:
    'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
  borderRadius: '8px',
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BoxPage = () => {
  const isMobileDevice = localStorage.getItem('isMobile');

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <>
      <Box mt={2} mb={2}>
        <Grid container sx={BoxesGridStyle}>
          <Grid sx={BoxesGridStyle2}>
            <div className={s.css_1}>
              <Box sx={BoxesGridStyle3}>
                <AllInboxIcon sx={BoxesIconStyle1} />
              </Box>
              <Typography sx={BoxesTypographyStyle1}>ШУНО</Typography>
            </div>
          </Grid>
          <Grid sx={BoxesGridStyle2}>
            <Paper sx={BoxesPaperStyle1}>
              <Grid
                container
                sx={isMobileDevice == 'true' ? BoxesGridContainerStyle : BoxesGridStyle8}
              >
                <Grid sx={isMobileDevice == 'true' ? BoxesGridStyle7 : BoxesGridStyle4}>
                  <ImageList
                    variant='masonry'
                    cols={isMobileDevice == 'true' ? undefined : 3}
                    gap={8}
                    sx={{
                      height: 450,
                      overflow: 'auto',
                      margin: 'auto',
                      background: '#e5f3fd',
                      padding: '8px',
                      borderRadius: '10px',
                    }}
                  >
                    {itemData.map((item) => (
                      <ImageListItem key={item.img}>
                        <img src={`${item.img}`} alt={item.title} loading='lazy' />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Grid>
                <Grid sx={BoxesGridStyle5}>
                  <Chip label='Закрыт' color='success' sx={BoxesChipStyle} />
                  <Typography sx={BoxesTypographyStyle2}>Пост Коянды</Typography>
                  <Typography sx={BoxesTypographyStyle3}>ШУНО №546543214</Typography>
                  <Box sx={BoxesBoxStyle3}>
                    <Typography sx={BoxesTypographyStyle6}>Количество линий:</Typography>
                    <Typography sx={BoxesTypographyStyle6}>17</Typography>
                  </Box>
                  <Box sx={BoxesBoxStyle3}>
                    <Typography sx={BoxesTypographyStyle6}>Ответственный:</Typography>
                    <Typography sx={BoxesTypographyStyle6}>Трим Р. М.</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid sx={BoxesGridStyle6}>
            <Paper sx={BoxesPaperStyle2}>
              <Box sx={BoxesBoxStyle4}>
                <Tabs value={value} onChange={handleChange}>
                  <Tab label='Описание' {...a11yProps(0)} />
                  <Tab label='Тех. данные' {...a11yProps(1)} />
                  <Tab label='Заметки' {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                Местоположение и т.п.
              </TabPanel>
              <TabPanel value={value} index={1}>
                Сколько линий
              </TabPanel>
              <TabPanel value={value} index={2}>
                Ключ под ковром
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BoxPage;
