import React, { CSSProperties, useEffect } from 'react';

const StylesModule = {
  TypographyStyle1: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#23366bde',
    fontFamily: 'Montserrat, sans-serif',
    lineHeight: '1.5',
  },

  TypographyStyle2: {
    margin: '16px 0px 0px',
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '1.65',
    color: 'rgb(95, 116, 141)',
  },

  TypographyStyle3: {
    fontSize: '13px',
    color: '#23366bde',
    fontWeight: '600',
    lineHeight: '1.65',
    fontFamily: 'Montserrat, sans-serif',
  },

  BoxStyle: {
    paddingTop: '16px',
    paddingBottom: '32px',
  },

  BoxStyle2: {
    padding: '24px',
    //   height: '100%',
    borderRadius: '4px',
    backgroundColor: 'rgb(239, 243, 245)',
  },

  PaperStyle: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: '#23366bde',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundImage: 'none',
    overflow: 'hidden',
    boxShadow:
      'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
    borderRadius: '8px',
    padding: '2rem 1rem',
  },

  GridStyle: {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row wrap',
    marginTop: '-24px',
    width: 'calc(100% + 24px)',
    marginLeft: '-24px',
  },

  GridStyle2: {
    paddingLeft: '24px',
    paddingTop: '24px',
    width: '50%',
  },

  GridStyle2Mobile: {
    paddingLeft: '24px',
    paddingTop: '24px',
  },
};

export default StylesModule;
