import * as React from 'react';
import { useState, useEffect } from 'react';
import api from '../../api/api';
import Chart from 'react-apexcharts';

type StatDataAmpers = {
  series: { data: any }[];
};

interface ApexOptions {
  annotations?: ApexAnnotations;
  chart?: ApexChart;
  colors?: any[];
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  forecastDataPoints?: ApexForecastDataPoints;
  grid?: ApexGrid;
  labels?: string[];
  legend?: ApexLegend;
  markers?: ApexMarkers;
  noData?: ApexNoData;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
  states?: ApexStates;
  stroke?: ApexStroke;
  subtitle?: ApexTitleSubtitle;
  theme?: ApexTheme;
  title?: ApexTitleSubtitle;
  tooltip?: ApexTooltip;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis | ApexYAxis[];
}

const options: ApexOptions = {
  chart: {
    height: 350,
    type: 'heatmap',
  },
  dataLabels: {
    enabled: false,
  },
  colors: ['#008FFB'],
  title: {
    text: 'Линии по постам',
    align: 'center',
    margin: 20,
    style: {
      color: 'rgb(95, 116, 141)',
      fontSize: '13px',
      fontWeight: '600',
      fontFamily: 'Montserrat, sans-serif',
    },
  },
};

const data = {
  series: [
    {
      name: 'Пост 1',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Пост 2',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Пост 3',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Пост 4',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Пост 5',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Пост 6',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Пост 7',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Пост 8',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: 'Пост 9',
      data: generateData(18, {
        min: 0,
        max: 90,
      }),
    },
  ],
};

export default function StatChartSecond() {
  //   const [StatDataAmpers, setStatDataAmpers] = useState<StatDataAmpers>({ series: [] });

  //   useEffect(() => {
  //     FetchApi();
  //   }, []);

  //   const FetchApi = async () => {
  //     const ampersPostGroup = {
  //       postID: 'KND1',
  //       groupID: '1.A',
  //     };
  //     const getAndFormateAmpers = await api.getAndFormateAmpers(ampersPostGroup);
  //     //   console.log(getAndFormateAmpers);

  //     const getAndFormateAmpersSeries = {
  //       series: [
  //         {
  //           data: getAndFormateAmpers,
  //         },
  //       ],
  //     };

  //     setStatDataAmpers(getAndFormateAmpersSeries);
  //   };

  console.log(data.series);
  return (
    <>
      <div id='chart'>
        <Chart
          options={options}
          series={data.series}
          type='heatmap'
          height={'230px'}
          width={'100%'}
        />
      </div>
    </>
  );
}
function generateData(arg0: number, arg1: { min: number; max: number }) {
  var i = 0;
  var series = [];
  while (i < arg0) {
    var x = (i + 1).toString();
    var y = Math.floor(Math.random() * (arg1.max - arg1.min + 1)) + arg1.min;

    series.push({
      x: x,
      y: y,
    });
    i++;
  }
  return series;
}
