import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ElectricalServicesTwoToneIcon from '@mui/icons-material/ElectricalServicesTwoTone';
import { RootState } from '../../redux/rootReducer';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: 'center',
  margin: 'auto !important',
  borderRadius: '8px',
  color: 'rgb(95, 116, 141)',
  fontSize: '13px',
  fontWeight: '600',
  fontFamily: 'Montserrat, sans-serif',
  boxShadow:
    'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
}));

export default function StreetLightGroupStatus() {
  const isMobileDevice = localStorage.getItem('isMobile');
  const [checked, setChecked] = React.useState(['wifi']);
  const { ProfileBlockHeight } = useSelector((state: RootState) => state.AppControl);
  const { MinorCardBlockHeight } = useSelector((state: RootState) => state.AppControl);

  console.log(ProfileBlockHeight, MinorCardBlockHeight);
  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  return (
    <>
      <Grid item xs={isMobileDevice == 'true' ? 12 : 6}>
        <Item sx={{ height: ProfileBlockHeight - MinorCardBlockHeight - 16, overflow: 'auto' }}>
          {' '}
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            subheader={
              <ListSubheader
                sx={{
                  color: 'rgb(95, 116, 141)',
                  fontSize: '13px',
                  fontWeight: '600',
                  fontFamily: 'Montserrat, sans-serif',
                }}
              >
                Линии освещения поста
              </ListSubheader>
            }
          >
            <ListItem>
              <ListItemIcon>
                <ElectricalServicesTwoToneIcon color='info' />
              </ListItemIcon>
              <ListItemText id='switch-list-label-wifi' primary='Коянды А.1' />
              <Switch
                edge='end'
                onChange={handleToggle('wifi')}
                checked={checked.indexOf('wifi') !== -1}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-wifi',
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ElectricalServicesTwoToneIcon color='info' />
              </ListItemIcon>
              <ListItemText id='switch-list-label-bluetooth' primary='Коянды B.1' />
              <Switch
                edge='end'
                onChange={handleToggle('bluetooth')}
                checked={checked.indexOf('bluetooth') !== -1}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-bluetooth',
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ElectricalServicesTwoToneIcon color='info' />
              </ListItemIcon>
              <ListItemText id='switch-list-label-bluetooth' primary='Коянды C.1' />
              <Switch
                edge='end'
                onChange={handleToggle('bluetooth')}
                checked={checked.indexOf('bluetooth') !== -1}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-bluetooth',
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ElectricalServicesTwoToneIcon color='info' />
              </ListItemIcon>
              <ListItemText id='switch-list-label-bluetooth' primary='Коянды C.1' />
              <Switch
                edge='end'
                onChange={handleToggle('bluetooth')}
                checked={checked.indexOf('bluetooth') !== -1}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-bluetooth',
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ElectricalServicesTwoToneIcon color='info' />
              </ListItemIcon>
              <ListItemText id='switch-list-label-bluetooth' primary='Коянды C.1' />
              <Switch
                edge='end'
                onChange={handleToggle('bluetooth')}
                checked={checked.indexOf('bluetooth') !== -1}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-bluetooth',
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ElectricalServicesTwoToneIcon color='info' />
              </ListItemIcon>
              <ListItemText id='switch-list-label-bluetooth' primary='Коянды C.1' />
              <Switch
                edge='end'
                onChange={handleToggle('bluetooth')}
                checked={checked.indexOf('bluetooth') !== -1}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-bluetooth',
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ElectricalServicesTwoToneIcon color='info' />
              </ListItemIcon>
              <ListItemText id='switch-list-label-bluetooth' primary='Коянды C.1' />
              <Switch
                edge='end'
                onChange={handleToggle('bluetooth')}
                checked={checked.indexOf('bluetooth') !== -1}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-bluetooth',
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ElectricalServicesTwoToneIcon color='info' />
              </ListItemIcon>
              <ListItemText id='switch-list-label-bluetooth' primary='Коянды C.1' />
              <Switch
                edge='end'
                onChange={handleToggle('bluetooth')}
                checked={checked.indexOf('bluetooth') !== -1}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-bluetooth',
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ElectricalServicesTwoToneIcon color='info' />
              </ListItemIcon>
              <ListItemText id='switch-list-label-bluetooth' primary='Коянды C.1' />
              <Switch
                edge='end'
                onChange={handleToggle('bluetooth')}
                checked={checked.indexOf('bluetooth') !== -1}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-bluetooth',
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ElectricalServicesTwoToneIcon color='info' />
              </ListItemIcon>
              <ListItemText id='switch-list-label-bluetooth' primary='Коянды C.1' />
              <Switch
                edge='end'
                onChange={handleToggle('bluetooth')}
                checked={checked.indexOf('bluetooth') !== -1}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-bluetooth',
                }}
              />
            </ListItem>
          </List>
        </Item>
      </Grid>
    </>
  );
}
