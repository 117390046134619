import React from 'react';
import s from '../Styles/Pages/login.module.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import api from '../api/api';
const LoginPage = () => {
  const [valueLogin, setValueLogin] = React.useState('');
  const [valuePass, setValuePass] = React.useState('');

  const valueLoginChange = (value: any) => {
    setValueLogin(value);
  };

  const valuePassChange = (value: any) => {
    setValuePass(value);
  };

  const fetchLogin = async () => {
    console.log(valueLogin);
    console.log(valuePass);
    const fs = new FormData();
    fs.append('l', valueLogin);
    fs.append('p', valuePass);
    console.log(fs);
    const data = {
      l: valueLogin,
      p: valuePass,
    };
    const response = await api.Login(data);
    console.log(response);

    if (response) {
      localStorage.setItem('login', 'true');
      localStorage.setItem('id', response[0].id);
      localStorage.setItem('first_name', response[0].first_name);
      localStorage.setItem('last_name', response[0].last_name);
      localStorage.setItem('father_name', response[0].father_name);
      localStorage.setItem('email', response[0].email);
      localStorage.setItem('img', response[0].img);

      window.location.reload();
    }
  };

  return (
    <>
      <div className={s.MainPageBody}>
        <div className={s.LoginWindow}>
          <div className={s.Login}>
            <TextField
              error={false}
              id='outlined-error-helper-text'
              label='Логин'
              onChange={(e) => valueLoginChange(e.target.value)}
              sx={{
                marginBottom: '10px',
                background: '#b3c6ed61',
                borderRadius: '5px',
                border: '1px solid #f9f9f9',
              }}
            />
          </div>
          <div className={s.Password}>
            <TextField
              error={false}
              id='outlined-error-helper-text'
              label='Пароль'
              onChange={(e) => valuePassChange(e.target.value)}
              sx={{
                marginBottom: '10px',
                background: '#b3c6ed61',
                borderRadius: '5px',
                border: '1px solid #f9f9f9',
              }}
            />
          </div>
          <div className={s.Auth}>
            <Button
              variant='contained'
              disabled={valueLogin ? (valuePass ? false : true) : true}
              sx={{
                height: '40px',
                width: '150px',
                margin: 'auto',
                backgroundColor: '#b3c6ed61',
              }}
              onClick={fetchLogin}
            >
              Войти
            </Button>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default LoginPage;
