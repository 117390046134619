import React, { CSSProperties, useEffect } from 'react';

const StylesModule = {
  BoxesHeadButtonStyle: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    WebkitTapHighlightColor: 'transparent',
    outline: '0px',
    border: '0px',
    margin: '30px',
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    textDecoration: 'none',
    fontFamily: 'Montserrat, sans-serif',
    lineHeight: '1.75',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#2499ef',
    textTransform: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '0.6rem 1.5rem',
    fontSize: '12px',
    fontWeight: '600',
    minWidth: '84px',
    color: 'white',
    marginTop: '-24px',
  },

  Title: {
    textAlign: 'center',
    fontFamily: 'Montserrat,sans-serif',
    fontWeight: '550',
    fontSize: '19px',
    lineHeight: '1.5',
    color: '#51555b',
    background: 'blanchedalmond',
    padding: '7px',
  },

  AddUser: {
    textAlign: 'center',
    fontFamily: 'Montserrat,sans-serif',
    fontWeight: '550',
    fontSize: '19px',
    lineHeight: '1.5',
    color: '#51555b',
    background: '#bdbdbd',
    cursor: 'pointer',

    padding: '7px',
  },
};

export default StylesModule;
