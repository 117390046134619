import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import api from '../../api/api';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
  textAlign: 'center',
};

interface Props {
  modalClose: () => void;
  NewMarkerMapData: { lat: number; lng: number };
  resultFetchApiGroups: {
    [x: string]: any;
    id: number;
    groupID: string;
    postID: string;
    postName: string;
  };
}

const NewPostModal = (props: Props) => {
  const [open, setOpen] = React.useState(true);
  const [valueID, setValueID] = React.useState('');
  const [valueName, setValueName] = React.useState('');
  const [valueIDError, setValueIDError] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    props.modalClose();
  };

  const NewPostPush = async (obj: { lat: number; lng: number; id: string; name: string }) => {
    const response = await api.newPostReg(obj);
    props.modalClose();
  };

  const valueIDChange = (value: any) => {
    setValueID(value);
    checkGroupID(value);
  };

  const valueNameChange = (value: any) => {
    setValueName(value);
  };

  const checkGroupID = (value: any) => {
    console.log(value);
    const checkUsername = (obj: { postID: string }) => obj.postID == value;
    setValueIDError(props.resultFetchApiGroups.some(checkUsername));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Новый Пост
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Заполните поля ниже для регистрации нового поста
          </Typography>
          <TextField
            error={false}
            id='outlined-error-helper-text'
            label='Широта'
            // defaultValue='ID'
            defaultValue={props.NewMarkerMapData.lat}
            // helperText='Incorrect entry.'
            sx={{ marginTop: '10px' }}
          />
          <TextField
            error={false}
            id='outlined-error-helper-text'
            label='Долгота'
            defaultValue={props.NewMarkerMapData.lng}
            // helperText='Incorrect entry.'
            sx={{ marginTop: '10px' }}
          />
          <TextField
            error={valueIDError}
            id='outlined-error-helper-text'
            label='ID'
            // value={value}
            onChange={(e) => valueIDChange(e.target.value)}
            // defaultValue='ID'
            helperText={
              valueID
                ? valueIDError
                  ? 'Такое ID Поста уже зарегистрировано'
                  : ''
                : 'Обязательное поле'
            }
            sx={{ marginTop: '10px' }}
          />
          <TextField
            error={valueIDError}
            id='outlined-error-helper-text'
            label='Наименование'
            // value={value}
            onChange={(e) => valueNameChange(e.target.value)}
            // defaultValue='ID'
            helperText={valueName ? '' : 'Обязательное поле'}
            sx={{ marginTop: '10px' }}
          />
          <Button
            variant='contained'
            disabled={valueName ? (valueID ? (valueIDError ? true : false) : true) : true}
            sx={{
              height: '40px',
              width: '150px',
              //   position: 'absolute',
              //   zIndex: '1',
              display: 'block',
              margin: 'auto',
              marginTop: '40px',
              backgroundColor: '#454545d1',
            }}
            onClick={() =>
              NewPostPush({
                lat: props.NewMarkerMapData.lat,
                lng: props.NewMarkerMapData.lng,
                id: valueID,
                name: valueName,
              })
            }
          >
            Сохранить
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default NewPostModal;
