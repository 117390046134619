import * as React from 'react';
import { useState, useEffect } from 'react';
import s from '../../App.module.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../Styles/img/logo.jpg';
import MainHeader from './HeaderButtons';

export default function StatChartFirst() {
  const isMobile = localStorage.getItem('isMobile');
  const navigate = useNavigate();

  return (
    <>
      {isMobile == 'true' ? (
        <>
          <header
            className={s.AppHeader}
            style={{
              display: 'block',
              background: '#ffffff',
              height: '40px',
            }}
          >
            <div
              style={{
                cursor: 'pointer',
                background: 'none',
                margin: 'auto',
                width: '100%',
              }}
              className={s.AppHeaderPart1}
              onClick={() => {
                navigate('/');
              }}
            >
              <img src={logo} className={s.AppLogo} alt='logo' />
            </div>
          </header>
          <div className={s.AppHeaderMobile}>
            <MainHeader />
          </div>
        </>
      ) : (
        <header
          className={s.AppHeader}
          style={{
            display: 'flex-inline',
            height: '70px',
          }}
        >
          <div
            className={s.AppHeaderPart2}
            style={{
              justifyContent: 'none',
              marginTop: '0',
              margin: 'none',
            }}
          >
            <MainHeader />
          </div>
        </header>
      )}
    </>
  );
}
