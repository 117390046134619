import React, { CSSProperties, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import api from '../../api/api';
import StylesModule from './ConnectedLinesStyle';

interface Props {
  postID: string;
}

type Lines = {
  [x: string]: any;
  groupID: number;
  id: number;
  last_update: string;
  last_value: number;
};

const ConnectedLines = (props: Props) => {
  const [value, setValue] = React.useState(0);
  const [lines, setLines] = React.useState<Lines[]>([]);
  const isMobileDevice = localStorage.getItem('isMobile');
  const navigate = useNavigate();

  useEffect(() => {
    FetchApiUsers();
  }, []);

  const FetchApiUsers = async () => {
    const res2 = await api.getPostLinesbyID({ id: props.postID });
    console.log(res2);
    setLines(res2);
  };

  function createData(id: number, lineID: string, sost: string, history: string) {
    return { id, lineID, sost, history };
  }

  const rows = [
    createData(1, '1.C', 'Выключен', 'История'),
    createData(2, '1.C', 'Выключен', 'История'),
    createData(3, '1.C', 'Выключен', 'История'),
    createData(4, '1.C', 'Выключен', 'История'),
    createData(5, '1.C', 'Выключен', 'История'),
  ];

  return (
    <>
      <Box mt={2} mb={2}>
        <Box sx={StylesModule.ContentStyle}>
          <TableContainer component={Paper} sx={StylesModule.TableContStyle}>
            <Table sx={StylesModule.RowStyle} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell sx={StylesModule.TableCellStyleHead} align='right'>
                    #
                  </TableCell>
                  <TableCell sx={StylesModule.TableCellStyleHead} align='right'>
                    ID
                  </TableCell>
                  <TableCell sx={StylesModule.TableCellStyleHead} align='right'>
                    Состояние
                  </TableCell>
                  <TableCell sx={StylesModule.TableCellStyleHead} align='right'>
                    Последнее обноовление
                  </TableCell>
                  <TableCell sx={StylesModule.TableCellStyleHead} align='right'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lines.map((row, i) => (
                  <TableRow key={i} sx={StylesModule.RowStyle2}>
                    {/* <TableCell component='th' scope='row'>
                      {row.name}
                    </TableCell> */}
                    <TableCell sx={StylesModule.TableCellStyleFirst} align='right'>
                      {i}
                    </TableCell>
                    <TableCell sx={StylesModule.TableCellStyle} align='right'>
                      {row.groupID}
                    </TableCell>
                    <TableCell sx={StylesModule.TableCellStyle} align='right'>
                      {row.last_value < 1 ? 'Выключен' : 'Включен'}
                    </TableCell>
                    <TableCell sx={StylesModule.TableCellStyle} align='right'>
                      {row.last_update}
                    </TableCell>
                    <TableCell sx={StylesModule.TableCellStyleLast} align='right'>
                      История
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default ConnectedLines;
