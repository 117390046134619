import * as React from 'react';
import s from './App.module.css';
import { useNavigate } from 'react-router-dom';
import MainRoutes from './Common/Routes/MainRoutes';
import AppHeader from './components/Header/AppHeader';
import { useSelector, useDispatch } from 'react-redux';
import { setNotifications } from './redux/slices/WebSocketStore';
import { RootState } from './redux/rootReducer';
import { ToastContainer } from 'react-toastify';
import { newNotification } from './Common/toasts/sliceToasts';
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from './Pages/Login';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  osName,
  osVersion,
  mobileVendor,
  mobileModel,
  deviceDetect,
} from 'react-device-detect';
import SideMenu from './components/SideMenu/SideMenu';
import Container from '@mui/material/Container';

const App = () => {
  const routes = <MainRoutes />;
  const navigate = useNavigate();
  const ws = new WebSocket('wss:/lumen.bikli.kz:4236');
  const dispatch = useDispatch();
  const login = localStorage.getItem('login');

  const { SideMenuWidth } = useSelector((state: RootState) => state.AppControl);

  React.useEffect(() => {
    localStorage.setItem('isMobile', isMobile.toString());

    console.log('wsConnect 1');
    wsConnect();
  }, []);

  //START------------------------------------------- websocket
  const wsConnect = () => {
    console.log('wsConnect 2');

    ws.onopen = () => {
      console.log('Socket is opened.');
      gettingData();
    };

    ws.onclose = (e) => {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function () {
        wsConnect();
      }, 1000);
    };

    ws.onerror = (err) => {
      console.error('Socket encountered error: ', err, 'Closing socket');
      ws.close();
    };
  };

  const gettingData = () => {
    console.log('gettingData 1');

    ws.onmessage = async (e) => {
      try {
        console.log(e.data);
        const data = e.data;
        const obj = await JSON.parse(data);

        if (obj.type.name == 'notification') {
          dispatch(setNotifications(obj.notification));
          console.log(data.notification.text);
          newNotification(
            <div onClick={() => navigate('/notifications')}>{obj.notification.text}</div>,
          );
        }
      } catch {
        console.log('incorrect ws');
      }
    };
  };
  //END------------------------------------------- websocket
  console.log(SideMenuWidth);
  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {login ? (
        <div className={s.App}>
          <SideMenu />
          <Container sx={{ width: isMobile ? '100%' : `calc(100% - ${SideMenuWidth}px)` }}>
            <div style={{ width: '100%', height: isMobile ? '110%' : 'inherit' }}>
              <AppHeader />
              <div className={s.SwipeableViews} style={{ marginLeft: isMobile ? '0' : '6px' }}>
                {routes}
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <LoginPage />
      )}
    </>
  );
};

export default App;
