import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

type resultData = {
  id: number;
  value_1: number;
  date: Date;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: '№', width: 90 },
  { field: 'groupID', headerName: 'Group ID', width: 90 },

  {
    field: 'value_1',
    headerName: 'Числовое значение',
    width: 150,
  },
  {
    field: 'date',
    headerName: 'Дата обновления',
    width: 200,
  },
];

// const FetchApiAdd_A = async () => {
//   const response = await fetch('http://lumen.bikli.kz/Api/api/table_add_test.php?id=1.A&var=4.75', {
//     method: 'GET',
//   });
//   const json = response.json();
//   return json;
// };

// const FetchApiAdd_B = async () => {
//   const response = await fetch('http://lumen.bikli.kz/Api/api/table_add_test.php?id=1.B&var=4.75', {
//     method: 'GET',
//   });
//   const json = response.json();
//   return json;
// };

// const FetchApiAdd_C = async () => {
//   const response = await fetch('http://lumen.bikli.kz/Api/api/table_add_test.php?id=1.C&var=4.75', {
//     method: 'GET',
//   });
//   const json = response.json();
//   return json;
// };
// 51.1801, 71.446
export default function AmperLightValues() {
  const [result, setResult] = useState<resultData[]>([]);

  useEffect(() => {
    FetchApi();

    // setTimeout(FetchApiTime, 10);
    // setTimeout(FetchApiTime, 50);
    // setTimeout(FetchApiTime, 100);
    // setTimeout(FetchApiTime, 150);
    // setTimeout(FetchApiTime, 200);
    // setTimeout(FetchApiTime, 250);
    // setTimeout(FetchApiTime, 300);
    // setTimeout(FetchApiTime, 350);
    // setTimeout(FetchApiTime, 400);
    // setTimeout(FetchApiTime, 450);
    // setTimeout(FetchApiTime, 500);
    // setTimeout(FetchApiTime, 550);
    // setTimeout(FetchApiTime, 600);
    // setTimeout(FetchApiTime, 650);
    // setTimeout(FetchApiTime, 700);
    // setTimeout(FetchApiTime, 750);
    // setTimeout(FetchApiTime, 800);
    // setTimeout(FetchApiTime, 850);
    // setTimeout(FetchApiTime, 900);
  }, []);

  // const FetchApiTime = async () => {
  //   FetchApiAdd_A();
  //   FetchApiAdd_B();
  //   FetchApiAdd_C();
  // };

  const FetchApi = async () => {
    const response = await fetch('https://lumen.bikli.kz/Api/api/getLast.php', {
      method: 'GET',
    });
    const jsonData = await response.json();
    setResult(jsonData);
  };
  return (
    <>
      <Box sx={{ height: 800, width: '100%', background: '#fff', borderRadius: '10px' }}>
        <DataGrid
          rows={result}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[5]}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </>
  );
}
