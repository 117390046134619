import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import React from 'react';
import StylesModule from '../../Styles/DataTable/CommonItemsWrapperStyles';
import logo from '../../Styles/img/street_lights_1.png';
import { useNavigate } from 'react-router-dom';
import s from '../../Styles/Posts/posts.module.css';

type Columns = {
  title: string;
  data: string;
  navigate_to: string;
  align: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
};

type posts = {
  id: number;
  post_name: string;
  lines: number;
  person: string;
};

type users = {
  id: number;
  fio: string;
  img: string;
  email: string;
};

interface Props {
  rows: posts[] | users[];
  columns: Columns[];
  return_data?: (arg0: posts | users) => void;
}

const CommonItemsWrapper = (props: Props) => {
  const navigate = useNavigate();

  console.log(props.rows);
  console.log(props.columns);

  const navigate_to = (link: string) => {
    console.log(link);
    link !== 'none' ? navigate(link, { replace: true }) : null;
  };

  return (
    <Box sx={StylesModule.ContentStyle}>
      <TableContainer component={Paper} sx={StylesModule.TableContStyle}>
        <Table sx={StylesModule.RowStyle} aria-label='simple table'>
          <TableHead>
            <TableRow>
              {props.columns.map((elem, i) => (
                <TableCell key={i} sx={StylesModule.TableCellStyleHead} align={elem.align}>
                  {elem.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row, i) => (
              <TableRow key={i} sx={StylesModule.RowStyle2}>
                {props.columns.map((col, j) => {
                  if (col.data !== 'post_name' && col.data !== 'img' && col.data !== 'fio') {
                    return (
                      <TableCell
                        sx={
                          j == 0
                            ? col.navigate_to == 'none'
                              ? StylesModule.TableCellStyleFirst
                              : StylesModule.TableCellStyleFirstCursor
                            : j == props.columns.length - 1
                            ? col.navigate_to == 'none'
                              ? StylesModule.TableCellStyleLast
                              : StylesModule.TableCellStyleLastCursor
                            : col.navigate_to == 'none'
                            ? StylesModule.TableCellStyle
                            : StylesModule.TableCellStyleCursor
                        }
                        align={col.align}
                        onClick={() => navigate_to(col.navigate_to)}
                      >
                        {row[col.data as keyof typeof row]}
                      </TableCell>
                    );
                  } else if (col.data == 'post_name') {
                    return (
                      <TableCell
                        sx={
                          j == 0
                            ? col.navigate_to == 'none'
                              ? StylesModule.TableCellStyleFirst
                              : StylesModule.TableCellStyleFirstCursor
                            : j == props.columns.length - 1
                            ? col.navigate_to == 'none'
                              ? StylesModule.TableCellStyleLast
                              : StylesModule.TableCellStyleLastCursor
                            : col.navigate_to == 'none'
                            ? StylesModule.TableCellStyle
                            : StylesModule.TableCellStyleCursor
                        }
                        align={col.align}
                        onClick={() => navigate_to(col.navigate_to)}
                      >
                        <div className={s.css_1}>
                          <img src={logo} className={s.img_css}></img>
                          {row[col.data as keyof typeof row]}
                        </div>
                      </TableCell>
                    );
                  } else if (col.data == 'img') {
                    return (
                      <TableCell
                        sx={
                          j == 0
                            ? col.navigate_to == 'none'
                              ? StylesModule.TableCellStyleFirst
                              : StylesModule.TableCellStyleFirstCursor
                            : j == props.columns.length - 1
                            ? col.navigate_to == 'none'
                              ? StylesModule.TableCellStyleLast
                              : StylesModule.TableCellStyleLastCursor
                            : col.navigate_to == 'none'
                            ? StylesModule.TableCellStyle
                            : StylesModule.TableCellStyleCursor
                        }
                        align={col.align}
                        onClick={() => navigate_to(col.navigate_to)}
                      >
                        <div className={s.css_1}>
                          <img
                            src={
                              row[col.data as keyof typeof row]
                                ? 'https://lumen.bikli.kz/files/avatars/' +
                                  row[col.data as keyof typeof row]
                                : logo
                            }
                            className={s.img_css}
                          ></img>
                        </div>
                      </TableCell>
                    );
                  } else if (col.data == 'fio') {
                    return (
                      <TableCell
                        sx={
                          j == 0
                            ? col.navigate_to == 'none'
                              ? StylesModule.TableCellStyleFirst
                              : StylesModule.TableCellStyleFirstCursor
                            : j == props.columns.length - 1
                            ? col.navigate_to == 'none'
                              ? StylesModule.TableCellStyleLast
                              : StylesModule.TableCellStyleLastCursor
                            : col.navigate_to == 'none'
                            ? StylesModule.TableCellStyle
                            : StylesModule.TableCellStyleCursor
                        }
                        align={col.align}
                        onClick={() => (props.return_data ? props.return_data(row) : null)}
                      >
                        {row[col.data as keyof typeof row]}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CommonItemsWrapper;
