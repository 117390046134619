import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CurrentDisplayState = {
  menuOpen: boolean;
  SideMenuWidth: number;
  ProfileBlockHeight: number;
  MinorCardBlockHeight: number;
};

let initialState: CurrentDisplayState = {
  menuOpen: false,
  SideMenuWidth: 150,
  ProfileBlockHeight: 250,
  MinorCardBlockHeight: 150,
};

const AppControlStore = createSlice({
  name: 'AppControl',
  initialState,
  reducers: {
    setMenuOpen(state, action) {
      state.menuOpen = action.payload;
    },
    setSideMenuWidth(state, action) {
      state.SideMenuWidth = action.payload;
    },
    setProfileBlockHeight(state, action) {
      state.ProfileBlockHeight = action.payload;
    },
    setMinorCardBlockHeight(state, action) {
      state.MinorCardBlockHeight = action.payload;
    },
  },
});

export const { setMenuOpen, setSideMenuWidth, setProfileBlockHeight, setMinorCardBlockHeight } =
  AppControlStore.actions;

export default AppControlStore.reducer;
