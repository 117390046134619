import React, { CSSProperties } from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import s from '../Styles/BoxPage/BoxDetails.module.css';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

interface Props {
  FormDataObj: (arg0: {
    ValueFirstName: string;
    ValueSecondName: string;
    ValueFatherName: string;
    ValueLogin: string;
    ValuePass: string;
    ValueEmail: string;
  }) => void;
}

const GridStyle: CSSProperties = {
  paddingLeft: '24px',
  paddingTop: '24px',
  width: '60%',
};

const GridStyle2: CSSProperties = {
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'row wrap',
  marginTop: '-24px',
  width: 'calc(100% + 24px)',
  marginLeft: '-12px',
  justifyContent: 'space-around',
};

const PaperStyle: CSSProperties = {
  backgroundColor: 'rgb(255, 255, 255)',
  color: 'rgb(18, 31, 67)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  backgroundImage: 'none',
  overflow: 'hidden',
  boxShadow:
    'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
  borderRadius: '8px',
  padding: '24px',
};

const TextStyle: CSSProperties = {
  fontFamily: 'Montserrat,sans-serif',
  fontWeight: '550',
  fontSize: '15px',
  lineHeight: '1.5',
  letterSpacing: '1px',
  color: 'rgb(95 116 141 / 77%)',
};

const FirstBlock = (props: Props) => {
  const isMobileDevice = localStorage.getItem('isMobile');

  const [ValueFirstName, setValueFirstName] = React.useState('');
  const [ValueSecondName, setValueSecondName] = React.useState('');
  const [ValueFatherName, setValueFatherName] = React.useState('');
  const [ValueLogin, setValueLogin] = React.useState('');
  const [ValuePass, setValuePass] = React.useState('');
  const [ValueEmail, setValueEmail] = React.useState('');
  const [ValueEmailError, setValueEmailError] = React.useState(true);

  const onChange = (
    value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any,
    type: number,
  ) => {
    let data = {
      ValueFirstName: ValueFirstName,
      ValueSecondName: ValueSecondName,
      ValueFatherName: ValueFatherName,
      ValueLogin: ValueLogin,
      ValuePass: ValuePass,
      ValueEmail: ValueEmail,
    };

    console.log(value.target.value);

    if (type == 1) {
      setValueFirstName(value.target.value);
      data.ValueFirstName = value.target.value;
    } else if (type == 2) {
      setValueSecondName(value.target.value);
      data.ValueSecondName = value.target.value;
    } else if (type == 3) {
      setValueFatherName(value.target.value);
      data.ValueFatherName = value.target.value;
    } else if (type == 4) {
      var re =
        /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
      setValueEmailError(re.test(value.target.value));
      setValueEmail(value.target.value);
      data.ValueEmail = value.target.value;
    } else if (type == 5) {
      setValueLogin(value.target.value);
      data.ValueLogin = value.target.value;
    } else if (type == 6) {
      setValuePass(value.target.value);
      data.ValuePass = value.target.value;
    }

    console.log(data);
    ValueFirstName
      ? ValueSecondName
        ? ValueFatherName
          ? ValueLogin
            ? ValuePass
              ? ValueEmail
                ? ValueEmailError
                  ? props.FormDataObj(data)
                  : null
                : null
              : null
            : null
          : null
        : null
      : null;
  };

  return (
    <>
      <Grid sx={GridStyle}>
        <Paper sx={PaperStyle}>
          <Box
            component='form'
            sx={{
              '& .MuiTextField-root': {
                m: 1,
                width: '25ch',
              },
              '& .MuiInputBase-input': {
                fontFamily: 'Montserrat,sans-serif',
                fontWeight: '550',
                fontSize: '15px',
                lineHeight: '1.5',
                letterSpacing: '1px',
                color: 'rgb(95 116 141 / 77%)',
              },
            }}
            // noValidate
            // autoComplete='off'
          >
            <Grid container sx={GridStyle2}>
              <TextField
                onChange={(e) => {
                  onChange(e, 1);
                }}
                onPaste={(e) => {
                  onChange(e, 1);
                }}
                required
                label='Имя'
              />
              <TextField
                onChange={(e) => {
                  onChange(e, 2);
                }}
                onPaste={(e) => {
                  onChange(e, 2);
                }}
                required
                label='Фамилия'
              />
              <TextField
                onChange={(e) => {
                  onChange(e, 3);
                }}
                onPaste={(e) => {
                  onChange(e, 3);
                }}
                required
                label='Отчество'
              />
              <TextField
                error={!ValueEmailError}
                helperText={!ValueEmailError ? 'Некорректная почта' : ''}
                onChange={(e) => {
                  onChange(e, 4);
                }}
                onPaste={(e) => {
                  onChange(e, 4);
                }}
                // onBlur={}
                required
                label='Email'
              />
              <TextField
                onChange={(e) => {
                  onChange(e, 5);
                }}
                onPaste={(e) => {
                  onChange(e, 5);
                }}
                required
                label='Логин'
              />
              <TextField
                onChange={(e) => {
                  onChange(e, 6);
                }}
                onPaste={(e) => {
                  onChange(e, 6);
                }}
                required
                label='Пароль'
              />
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </>
  );
};

export default FirstBlock;
