import React, { CSSProperties } from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import s from '../../Styles/BoxPage/BoxDetails.module.css';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DnDC from '../AddFiles/images/DnDC.js';
import LoadingImg from '../AddFiles/images/LoadingImg';
import Modal from '@mui/material/Modal';

interface Props {
  FormData: (arg0: number) => void;
  DisableButtonSend: boolean;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 512,
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  boxSizing: 'border-box',
  padding: '48px 48px 32px 48px',
};

const GridStyle: CSSProperties = {
  paddingLeft: '24px',
  paddingTop: '24px',
};

const GridStyle2: CSSProperties = {
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'row wrap',
  marginTop: '-24px',
  width: 'calc(100% + 24px)',
  marginLeft: '-24px',
};

const PaperStyle: CSSProperties = {
  backgroundColor: 'rgb(255, 255, 255)',
  color: 'rgb(18, 31, 67)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  backgroundImage: 'none',
  overflow: 'hidden',
  boxShadow:
    'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
  borderRadius: '8px',
  padding: '24px',
  minHeight: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const BoxStyle: CSSProperties = {
  width: '100px',
  height: '100px',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(18, 31, 67, 0.12)',
};

const ButtonStyle: CSSProperties = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  WebkitTapHighlightColor: 'transparent',
  outline: '0px',
  border: '0px',
  margin: '30px',
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  appearance: 'none',
  textDecoration: 'none',
  fontFamily: 'Montserrat, sans-serif',
  lineHeight: '1.75',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  backgroundColor: '#2499ef',
  textTransform: 'none',
  borderRadius: '4px',
  boxShadow: 'none',
  padding: '0.6rem 1.5rem',
  fontSize: '12px',
  fontWeight: '600',
  minWidth: '84px',
  color: 'white',
};

const BoxStyle2: CSSProperties = {
  width: '50px',
  height: '50px',
  display: 'flex',
  borderRadius: '50%',
  border: '2px solid rgb(255, 255, 255)',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(18, 31, 67, 0.12)',
  cursor: 'pointer',
};

const BoxStyle4: CSSProperties = {
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '1.9',
  marginTop: '16px',
  maxWidth: '200px',
  display: 'block',
  textAlign: 'center',
  color: 'rgb(95, 116, 141)',
};
const SecondBlock = (props: Props) => {
  const isMobileDevice = localStorage.getItem('isMobile');
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [droppedFiles, setDroppedFiles] = React.useState([]);
  const [item, setItem] = React.useState([]);
  const [countItems, setCountItems] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);

  const FormSend = () => {
    // props.FormData(2);

    let files: any = [];
    for (let i = 0; i < droppedFiles.length; i++) {
      files.push(droppedFiles[i]);
    }

    if (files.length == 1) {
      console.log('ok - number');

      console.log(files[0].size);
      console.log(files[0].size / 1024 / 1024);

      if (files[0].size / 1024 / 1024 < 1) {
        console.log('ok - size');
        props.FormData(files);
      } else {
        console.log('not ok - size');
      }
    } else {
      console.log('not ok - number');
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDroppedFiles([]);
  };

  const handleSaveClose = () => {
    setOpen(false);
    console.log(droppedFiles);
  };

  return (
    <>
      <Grid sx={GridStyle}>
        <Paper sx={PaperStyle}>
          <Box sx={BoxStyle}>
            <Box sx={BoxStyle2} onClick={handleOpen}>
              <AddPhotoAlternateIcon />
            </Box>
          </Box>
          <Box sx={BoxStyle4}>Допустимые форматы - *.jpeg, *.jpg, *.png размером до 1 MB</Box>
          <Button
            variant='contained'
            sx={ButtonStyle}
            onClick={FormSend}
            disabled={props.DisableButtonSend}
          >
            Добавить пользователя
          </Button>
        </Paper>
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className={s.close_auth_modal} onClick={handleClose}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16.2132 2.07107L2.07102 16.2132M2.07102 2.07107L16.2132 16.2132'
                stroke='#292929'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className={s.title_file_modal}>
            <div className={s.title_file_modal}>Добавление фото</div>
          </div>
          <DndProvider backend={HTML5Backend}>
            <DnDC
              handleClose={handleClose}
              handleSaveClose={handleSaveClose}
              droppedFiles={droppedFiles}
              setDroppedFiles={setDroppedFiles}
              setCountItems={setCountItems}
              setItem={setItem}
            />
          </DndProvider>
        </Box>
      </Modal>
    </>
  );
};

export default SecondBlock;
