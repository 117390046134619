import React, { CSSProperties, useEffect } from 'react';

const StylesModule = {
  HeadStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },

  ContentStyle: {},

  RowStyle: {
    borderSpacing: '0px 12px',
    borderCollapse: 'separate',
  },

  RowStyle2: {
    background: '#ffffff',
  },

  TableContStyle: {
    backgroundColor: '#fff0',
    boxShadow: 'none',
  },

  TableCellStyle: {
    display: 'table-cell',
    verticalAlign: 'inherit',
    /* borderBottom: "1px solid rgba(224, 224, 224, 1)",
     */ textAlign: 'center',
    padding: '16px',
    color: 'rgb(95 116 141 / 77%)',
    WebkitFlexDirection: 'row-reverse',
    flexDirection: 'row-reverse',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '550',
    fontSize: '15px',
    lineHeight: '1.5',
    letterSpacing: '3px',
    background: '#ffffff',
  },

  TableCellStyleHead: {
    display: 'table-cell',
    verticalAlign: 'inherit',
    /* borderBottom: "1px solid rgba(224, 224, 224, 1)",
     */ textAlign: 'center',
    padding: '16px',
    color: 'rgb(95 116 141 / 77%)',
    WebkitFlexDirection: 'row-reverse',
    flexDirection: 'row-reverse',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '550',
    fontSize: '15px',
    lineHeight: '1.5',
    letterSpacing: '3px',
  },

  TableCellStyleFirst: {
    display: 'table-cell',
    verticalAlign: 'inherit',
    /* borderBottom: "1px solid rgba(224, 224, 224, 1)",
     */ textAlign: 'center',
    padding: '16px',
    color: 'rgb(95 116 141 / 77%)',
    WebkitFlexDirection: 'row-reverse',
    flexDirection: 'row-reverse',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '550',
    fontSize: '15px',
    lineHeight: '1.5',
    letterSpacing: '3px',
    background: '#ffffff',
    borderRadius: '9px 0 0 9px',
  },

  TableCellStyleLast: {
    display: 'table-cell',
    verticalAlign: 'inherit',
    /* borderBottom: "1px solid rgba(224, 224, 224, 1)",
     */ textAlign: 'center',
    padding: '16px',
    color: 'rgb(95 116 141 / 77%)',
    WebkitFlexDirection: 'row-reverse',
    flexDirection: 'row-reverse',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '550',
    fontSize: '15px',
    lineHeight: '1.5',
    letterSpacing: '3px',
    background: '#ffffff',
    borderRadius: '0 9px 9px 0',
  },
};

export default StylesModule;
