import React, { useCallback, useState } from 'react';

import { FileList } from './FileList.js';
import { TargetBox } from './TargetBox.js';
import s from '../Gallery.module.css';
import { useSelector } from 'react-redux';
import api from '../../../../api/api.tsx';
// import LoadingDots from '../../../components/LoadingDots/LoadingDots';

export const Container = ({
  handleClose,
  setDroppedFiles,
  droppedFiles,
  setItem,
  setCountItems,
  handleSaveClose,
}) => {
  const userId = 13231;

  const inputFiles = React.useRef();

  const [loadErr, setLoadErr] = useState(false);
  const [loadSubErr, setLoadSubErr] = useState([]);
  const [isLoadingPhotos, setIsloadingPhotos] = useState(false);
  const [isLoadingSuccess, setIsLoadingSuccess] = useState(false);
  const [isLoadingDuplicate, setIsLoadingDuplicate] = useState([]);
  const [isLoadingDuplicateSucc, setIsLoadingDuplicateSucc] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);

  const handleFileDrop = useCallback(
    (item) => {
      setLoadErr(false);
      setLoadSubErr(false);
      setIsLoadingSuccess(false);
      setDroppedFiles([]);
      setIsLoadingDuplicateSucc([]);
      setIsLoadingDuplicate([]);

      if (item) {
        const files = item.files;
        setDroppedFiles(files);
      }
    },
    [setDroppedFiles],
  );

  const getGallery = () => {
    const response = api.fetchGallery(1);
    response
      .then((res) => {
        setItem(res[1]);
        setCountItems(res[0].count);
      })
      .catch((err) => console.log(err));
  };

  const getfiles = () => {
    setLoadErr(false);
    setLoadSubErr(false);
    setIsLoadingSuccess(false);
    setDroppedFiles([]);
    setIsLoadingDuplicateSucc([]);
    setIsLoadingDuplicate([]);
    inputFiles.current.click();
  };

  const FindFile = async (e) => {
    setIsLoadingSuccess(false);
    setDroppedFiles(inputFiles.current.files);
  };

  const LoadFiles = () => {
    setIsloadingPhotos(true);
    setIsLoadingDuplicateSucc([]);
    setLoadErr(false);
    setLoadSubErr(false);
    setIsLoadingSuccess(false);
    setIsLoadingDuplicate([]);
    const fd = new FormData();
    fd.append('id', userId);
    const ins = droppedFiles.length;
    for (let x = 0; x < ins; x++) {
      if (
        droppedFiles[x].name.split('.').splice(-1, 1)[0] === 'png' ||
        droppedFiles[x].name.split('.').splice(-1, 1)[0] === 'jpeg' ||
        droppedFiles[x].name.split('.').splice(-1, 1)[0] === 'jpg'
      ) {
        const fileSizeLt2 = String(droppedFiles[x].size / 1024 / 1024);
        if (fileSizeLt2.split('.')[0] >= 1) {
          setLoadErr('Не удалось загрузить файл!');
          setLoadSubErr('Размер файла не должен превышать 1 мб.');

          return;
        }
        fd.append('img[]', droppedFiles[x]);
      } else {
        setLoadErr('Не удалось загрузить файл!');
        setLoadSubErr('Тип файла недоступен для загрузки. Выберите файл формата png, jpeg, jpg.');
      }
    }
    if (droppedFiles.length > 0) {
      setDroppedFiles(droppedFiles);
      handleSaveClose();
      // const response = api.sendFiles(fd);
      // let loadingDublicate = true;
      // response.then((res) => {
      //   res.map((item) => {
      //     if (item.message.split(' ').splice(-1, 1)[0] === 'есть') {
      //       setIsLoadingDuplicate([...res]);
      //       setIsDuplicate(true);
      //       loadingDublicate = false;
      //       setIsloadingPhotos(false);
      //     } else {
      //       if (loadingDublicate) {
      //         setLoadErr(false);
      //         setIsLoadingDuplicateSucc([]);
      //         setIsLoadingSuccess('Файлы загружены!');
      //         setIsloadingPhotos(false);
      //       }
      //     }
      //     getGallery();
      //   });
      // });
    } else {
      setLoadErr('Файлов не обнаружено!');
      setLoadSubErr('Выберите файл и затем нажмите сохранить.');
      setIsloadingPhotos(false);
    }
  };

  const replaceFiles = () => {
    setIsloadingPhotos(true);
    setIsDuplicate(false);
    let filterDublicate = [];
    let filterDropFiles = [];
    for (let i = 0; i < isLoadingDuplicate.length; i++) {
      filterDublicate = isLoadingDuplicate.filter(
        (item) => item?.message.split(' ').splice(-1, 1)[0] === 'есть',
      );
    }
    for (let i = 0; i < droppedFiles.length; i++) {
      if (
        droppedFiles[i].name ===
        filterDublicate[i]?.message
          .replace('Такой файл', '')
          .replace('"', '')
          .replace('"', '')
          .replace('уже есть', '')
          .trim()
      ) {
        filterDropFiles.push(droppedFiles[i]);
      }
    }
    const fd = new FormData();
    fd.append('id', userId);
    if (filterDropFiles.length > 0) {
      const ins = filterDropFiles.length;
      for (let x = 0; x < ins; x++) {
        fd.append('img[]', filterDropFiles[x]);
      }
    }
    const response = api.replaceFiles(fd);
    response.then((res) => {
      setLoadErr(false);
      setIsLoadingDuplicateSucc([...res]);
      setIsLoadingDuplicate([]);
      setDroppedFiles([]);
      setIsLoadingSuccess('Файлы загружены! ');
      getGallery();
    });
    setIsloadingPhotos(false);
  };

  return (
    <>
      {(!isLoadingDuplicate.filter((item) => item.message.split(' ').splice(-1, 1)[0] === 'есть')
        .length > 0 ||
        !isLoadingDuplicateSucc) && (
        <TargetBox
          onDrop={handleFileDrop}
          inputFiles={inputFiles}
          getfiles={getfiles}
          FindFile={FindFile}
        />
      )}
      <FileList files={droppedFiles} />
      {loadErr && <div className={s.errorLoad}>{loadErr}</div>}
      {loadSubErr && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          {loadSubErr}
        </div>
      )}
      {isLoadingDuplicate && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          {isLoadingDuplicate.map((item, i) => {
            return item.message.split(' ').splice(-1, 1)[0] === 'есть' ? (
              <div key={i} style={{ color: 'red' }}>
                {item.message}
              </div>
            ) : (
              <div key={i}>{item.message}</div>
            );
          })}
        </div>
      )}

      {isLoadingPhotos && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          {/* <LoadingDots /> */}
        </div>
      )}
      {isLoadingSuccess && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <div>{isLoadingSuccess}</div>
        </div>
      )}
      {isDuplicate ? (
        <>
          <div className={s.isReplace}>Заменить повторяющиеся объекты?</div>
          <button className={s.btn} style={{ marginTop: '40px' }} onClick={replaceFiles}>
            Да, заменить
          </button>
          <button className={s.btnTwo} onClick={handleClose}>
            Отмена
          </button>
        </>
      ) : (
        <>
          <button className={s.btn} style={{ marginTop: '40px' }} onClick={LoadFiles}>
            Сохранить
          </button>
          <button className={s.btnTwo} onClick={handleClose}>
            Отмена
          </button>
        </>
      )}
    </>
  );
};
