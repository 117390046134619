import * as React from 'react';
import { useState, useEffect } from 'react';
import api from '../../api/api';
import Chart from 'react-apexcharts';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ElectricalServicesTwoToneIcon from '@mui/icons-material/ElectricalServicesTwoTone';

interface ApexOptions {
  annotations?: ApexAnnotations;
  chart?: ApexChart;
  colors?: any[];
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  forecastDataPoints?: ApexForecastDataPoints;
  grid?: ApexGrid;
  labels?: string[];
  legend?: ApexLegend;
  markers?: ApexMarkers;
  noData?: ApexNoData;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
  states?: ApexStates;
  stroke?: ApexStroke;
  subtitle?: ApexTitleSubtitle;
  theme?: ApexTheme;
  title?: ApexTitleSubtitle;
  tooltip?: ApexTooltip;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis | ApexYAxis[];
}

const optionsTemp: ApexOptions = {
  chart: {
    type: 'boxPlot',
    height: '100%',
  },
  title: {
    text: 'Температура на линии',
    align: 'center',
    margin: 40,
    style: {
      color: 'rgb(95, 116, 141)',
      fontSize: '13px',
      fontWeight: '600',
      fontFamily: 'Montserrat, sans-serif',
    },
  },
  plotOptions: {
    boxPlot: {
      colors: {
        upper: 'rgb(71, 174, 252)',
        lower: 'rgb(148, 208, 253)',
      },
    },
  },
};

const dataTemp = {
  series: [
    {
      type: 'boxPlot',
      data: [
        {
          x: 'Пятница 20:15',
          y: [54, 66, 69, 75, 88],
        },
        {
          x: 'Пятница 20:16',
          y: [43, 65, 69, 76, 81],
        },
        {
          x: 'Пятница 20:17',
          y: [31, 39, 45, 51, 59],
        },
        {
          x: 'Пятница 20:18',
          y: [39, 46, 55, 65, 71],
        },
        {
          x: 'Пятница 20:19',
          y: [29, 31, 35, 39, 44],
        },
        {
          x: 'Пятница 20:20',
          y: [41, 49, 58, 61, 67],
        },
        {
          x: 'Пятница 20:21',
          y: [54, 59, 66, 71, 88],
        },
      ],
    },
  ],
};

export default function TempChart() {
  const isMobileDevice = localStorage.getItem('isMobile');
  const [checked, setChecked] = React.useState(['wifi']);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  return (
    <>
      <Chart options={optionsTemp} series={dataTemp.series} type='boxPlot' height={280} />
    </>
  );
}
