import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import profLogo from '../../Styles/img/001man.svg';
import { setProfileBlockHeight } from '../../redux/slices/AppControlSlice';
import StylesModule from '../../Styles/FirstPage/ProfileStyles';

const ItemProfile = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: 'center',
  color: 'rgb(95, 116, 141)',
  fontSize: '13px',
  fontWeight: '600',
  fontFamily: 'Montserrat, sans-serif',
  flexGrow: 1,
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: 'center',
  margin: 'auto !important',
  borderRadius: '8px',
  color: 'rgb(95, 116, 141)',
  fontSize: '13px',
  fontWeight: '600',
  fontFamily: 'Montserrat, sans-serif',
  boxShadow:
    'rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px, rgba(95, 116, 141, 0.08) 0px 1px 3px 0px',
}));
export default function ProfileBlock() {
  const dispatch = useDispatch();
  const divBlock = React.useRef<HTMLDivElement>(null);
  const avatar = localStorage.getItem('img');
  const first_name = localStorage.getItem('first_name');
  const last_name = localStorage.getItem('last_name');
  const father_name = localStorage.getItem('father_name');

  React.useEffect(() => {
    if (divBlock.current) {
      const height = divBlock.current.getBoundingClientRect().height;
      dispatch(setProfileBlockHeight(height));
    }
  }, []);

  return (
    <>
      <div ref={divBlock}>
        <Item>
          <Box sx={{ padding: '45px' }}>
            <Stack
              spacing={{ xs: 5, sm: 5 }}
              direction='row'
              useFlexGap
              gap={50}
              flexWrap='wrap'
              sx={{ justifyContent: 'center' }}
            >
              <div style={{ display: 'inline-flex' }}>
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '15px',
                  }}
                >
                  <Button
                    sx={{
                      margin: '10px',
                      fontSize: '12px',
                      fontWeight: '400',
                      width: '84px',
                      background: '#2499ef',
                    }}
                    variant='contained'
                  >
                    Профиль
                  </Button>
                  <Button
                    sx={{
                      margin: '10px',
                      fontSize: '12px',
                      fontWeight: '400',
                      width: '84px',
                      background: '#2499ef',
                    }}
                    variant='contained'
                  >
                    Настройки
                  </Button>
                </Box>
              </div>

              <Box sx={StylesModule.Box6}>
                <ItemProfile sx={{ marginBottom: '15px' }}>
                  <img
                    src={'https://lumen.bikli.kz/files/avatars/' + avatar}
                    style={{ width: '50px' }}
                  ></img>
                </ItemProfile>
                <Box sx={StylesModule.Box7}>{last_name}</Box>
                <Box sx={StylesModule.Box7}>{first_name}</Box>
                <Box sx={StylesModule.Box7}>{father_name}</Box>
              </Box>
              <ItemProfile>Ваша Роль: ____________________________</ItemProfile>
              <ItemProfile>Ваши Права: ___________________________</ItemProfile>
              <Button variant='contained' sx={{ background: '#2499ef' }}>
                История
              </Button>
            </Stack>
          </Box>
        </Item>
      </div>
    </>
  );
}
