import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
  textAlign: 'center',
};

interface Props {
  modalClose: () => void;
  NewMarkersPush: (obj: { lat: number; lng: number; id: string; postID: string }) => void;

  NewMarkerMapData: { lat: number; lng: number };
  resultFetchApiGroups: {
    [x: string]: any;
    id: number;
    groupID: string;
  };
  resultFetchApiPosts: {
    [x: string]: any;
    id: number;
    postID: string;
    postName: string;
    dolgota: number;
    shirota: number;
  };
}

const BasicModal = (props: Props) => {
  const [open, setOpen] = React.useState(true);
  const [valueID, setValueID] = React.useState('');
  const [postID, setPostID] = React.useState('');
  const [valueIDError, setValueIDError] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    props.modalClose();
  };

  const valueIDChange = (value: any) => {
    setValueID(value);
    // checkGroupID(value);
  };
  const valuePostIDChange = (value: any) => {
    setPostID(value.target.value);
    // checkGroupID(value);
  };

  const checkGroupID = (value: any) => {
    console.log(value);
    const checkUsername = (obj: { groupID: string }) => obj.groupID == value;
    setValueIDError(props.resultFetchApiGroups.some(checkUsername));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Столб освещения линии
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Заполните поля ниже для регистрации столба освещения линии
          </Typography>
          <TextField
            error={false}
            id='outlined-error-helper-text'
            label='Широта'
            // defaultValue='ID'
            defaultValue={props.NewMarkerMapData.lat}
            // helperText='Incorrect entry.'
            sx={{ marginTop: '10px' }}
          />
          <TextField
            error={false}
            id='outlined-error-helper-text'
            label='Долгота'
            defaultValue={props.NewMarkerMapData.lng}
            // helperText='Incorrect entry.'
            sx={{ marginTop: '10px' }}
          />
          <TextField
            error={valueIDError}
            id='outlined-error-helper-text'
            label='ID Линии'
            // value={value}
            onChange={(e) => valueIDChange(e.target.value)}
            // defaultValue='ID'
            helperText={valueID ? '' : 'Обязательное поле'}
            sx={{ marginTop: '10px' }}
          />

          <Box sx={{ minWidth: 120, marginTop: '10px' }}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Пост</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                // value={age}
                label='Age'
                onChange={valuePostIDChange}
              >
                {props.resultFetchApiPosts.map((data: { postName: string; postID: string }) => {
                  return <MenuItem value={data.postID}>{data.postName}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>

          {/* <TextField
            error={valueIDError}
            id='outlined-error-helper-text'
            label='ID Поста'
            // value={value}
            onChange={(e) => valuePostIDChange(e.target.value)}
            // defaultValue='ID'
            helperText={postID ? '' : 'Обязательное поле'}
            sx={{ marginTop: '10px' }}
          /> */}

          <Button
            variant='contained'
            disabled={valueID ? (postID ? false : true) : true}
            sx={{
              height: '40px',
              width: '150px',
              //   position: 'absolute',
              //   zIndex: '1',
              display: 'block',
              margin: 'auto',
              marginTop: '40px',
              backgroundColor: '#454545d1',
            }}
            onClick={() =>
              props.NewMarkersPush({
                lat: props.NewMarkerMapData.lat,
                lng: props.NewMarkerMapData.lng,
                id: valueID,
                postID: postID,
              })
            }
          >
            Сохранить
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default BasicModal;
