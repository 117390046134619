import React from 'react';
import { Container } from './DnD/Container';

const DnDC = ({
  handleClose,
  handleSaveClose,
  droppedFiles,
  setDroppedFiles,
  setCountItems,
  setItem,
}) => {
  return (
    <div>
      <Container
        handleClose={handleClose}
        handleSaveClose={handleSaveClose}
        setDroppedFiles={setDroppedFiles}
        droppedFiles={droppedFiles}
        setItem={setItem}
        setCountItems={setCountItems}
      />
    </div>
  );
};

export default DnDC;
