import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotificationsType {
  id: number;
  text: string;
  UserID: number;
  date: Date;
}

type CurrentDisplayState = {
  Notifications: NotificationsType[];
};

let initialState: CurrentDisplayState = {
  Notifications: [],
};

const WebSocketStore = createSlice({
  name: 'WebSocket',
  initialState,
  reducers: {
    setNotifications(state, action: PayloadAction<NotificationsType>) {
      // state.Notifications = action.payload;
      state.Notifications.push(action.payload);
    },
  },
});

export const { setNotifications } = WebSocketStore.actions;

export default WebSocketStore.reducer;
