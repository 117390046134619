import React, { CSSProperties, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import api from '../../api/api';
import StylesModule from './ConnectedUsersStyle';

type Users = {
  [x: string]: any;
  father_name: string;
  first_name: string;
  last_name: string;
};

interface Props {
  postID: string;
}

const ConnectedUsers = (props: Props) => {
  const [value, setValue] = React.useState(0);
  const [users, setUsers] = React.useState<Users[]>([]);
  const isMobileDevice = localStorage.getItem('isMobile');
  const navigate = useNavigate();

  useEffect(() => {
    FetchApiUsers();
  }, []);

  const FetchApiUsers = async () => {
    const res2 = await api.getUsersPostAccessWithData({ post: props.postID });
    console.log(res2);
    setUsers(res2);
  };

  function createData(id: number, FIO: string, Role: string, Access: string) {
    return { id, FIO, Role, Access };
  }

  const rows = [
    createData(1, 'Т. Т. Тим', 'Электрик', 'Чтение'),
    createData(2, 'Т. Т. Тим', 'Электрик', 'Чтение'),
    createData(3, 'Т. Т. Тим', 'Электрик', 'Чтение'),
    createData(4, 'Т. Т. Тим', 'Электрик', 'Чтение'),
    createData(5, 'Т. Т. Тим', 'Электрик', 'Чтение'),
  ];

  return (
    <>
      <Box>
        <Box sx={StylesModule.ContentStyle}>
          <TableContainer component={Paper} sx={StylesModule.TableContStyle}>
            <Table sx={StylesModule.RowStyle} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell sx={StylesModule.TableCellStyleHead} align='right'>
                    #
                  </TableCell>
                  <TableCell sx={StylesModule.TableCellStyleHead} align='right'>
                    ФИО
                  </TableCell>
                  <TableCell sx={StylesModule.TableCellStyleHead} align='right'>
                    Роль
                  </TableCell>
                  <TableCell sx={StylesModule.TableCellStyleHead} align='right'>
                    Права
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((row, i) => (
                  <TableRow
                    key={row.id}
                    sx={StylesModule.RowStyle2}
                    onClick={() => navigate('/post/123')}
                  >
                    {/* <TableCell component='th' scope='row'>
                      {row.name}
                    </TableCell> */}
                    <TableCell sx={StylesModule.TableCellStyleFirst} align='right'>
                      {i}
                    </TableCell>
                    <TableCell sx={StylesModule.TableCellStyle} align='right'>
                      {row.last_name + ' ' + row.first_name + ' ' + row.father_name}
                    </TableCell>
                    <TableCell sx={StylesModule.TableCellStyle} align='right'>
                      -
                    </TableCell>
                    <TableCell sx={StylesModule.TableCellStyleLast} align='right'>
                      -
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default ConnectedUsers;
