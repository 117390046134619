import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

type WSNotifications = {
  id: number;
  text: string;
  UserID: number;
  date: Date;
};

interface Props {
  open: boolean;
  anchorEl: null | HTMLElement;
  WSNotifications: WSNotifications[];
}

const NotificationPopper = (props: Props) => {
  const id = props.open ? 'simple-popper' : undefined;
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: '#486db26e',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Popper
        id={id}
        open={props.open}
        anchorEl={props.anchorEl}
        sx={{ background: '#282c3400', marginTop: '2px' }}
      >
        <Box
          sx={{
            border: '1px solid #82aae7',
            p: '3px',
            borderRadius: '4px',
            marginTop: '7px',
            backdropFilter: 'blur(2px)',
            width: '260px',
            whiteSpace: 'pre',
          }}
        >
          {props.WSNotifications.map((data) => (
            <Accordion
              expanded={expanded === 'panel' + data.id}
              onChange={handleChange('panel' + data.id)}
              sx={{
                background: '#486db3a6',
                color: 'aliceblue',
              }}
            >
              <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                <Typography>{'Оповещение' + ' #' + data.id}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontSize: '11px' }}>{data.text}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Popper>
    </div>
  );
};

export default NotificationPopper;
