import React, { CSSProperties, useEffect } from 'react';

const StylesModule = {
  Box5: {
    textAlign: 'center',
    fontFamily: 'Montserrat,sans-serif',
    fontWeight: '550',
    fontSize: '19px',
    lineHeight: '1.5',
    color: '#51555b',
  },

  Box6: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '360px',
    flexWrap: 'wrap',
    margin: '8px auto auto',
    flexDirection: 'column',
  },

  Box7: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Montserrat,sans-serif',
    fontWeight: '550',
    fontSize: '15px',
    lineHeight: '1.5',
    letterSpacing: '1px',
    color: 'rgb(95 116 141 / 77%)',
  },
};

export default StylesModule;
