import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import s from './map.module.css';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import api from '../../api/api';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  border: '2px solid #000',
  p: 4,
  textAlign: 'center',
  boxShadow:
    '0px 11px 15px -7px rgb(255 255 255), 0px 24px 38px 3px rgb(254 254 254 / 65%), 0px 9px 46px 8px rgb(249 249 249 / 98%)',
  padding: '32px',
  borderRadius: '10px',
  background: '#fffefeed',
};

interface Props {
  NewMarkersMapDataArray: {
    [x: string]: any;
    id: string;
    postID: string;
    lng: number;
    lat: number;
  };
  handleChangeTo: (num: number) => void;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1A2027',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'rgb(174 176 179)',
}));

const NewMarkersGroup = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const [valueID, setValueID] = React.useState('');
  const [valueIDError, setValueIDError] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    // props.modalClose();
  };

  const sendNewMarkers = async () => {
    const response = await api.newMarkersReg(props.NewMarkersMapDataArray);
    handleClose();
    props.handleChangeTo(0);
  };

  return (
    <div className={s.markerGroup}>
      <Button
        variant='contained'
        disabled={props.NewMarkersMapDataArray.length ? false : true}
        sx={{
          height: '40px',
          width: '150px',
          margin: 'auto',
          padding: '3px',
          backgroundColor: '#282c34',
          fontSize: '12px',
          lineHeight: '1.5',
        }}
        onClick={handleOpen}
      >
        Новые столбы освещения
      </Button>
      <Button
        variant='contained'
        disabled={true}
        sx={{
          height: '40px',
          width: '150px',
          margin: 'auto',
          backgroundColor: '#282c34',
        }}
      ></Button>
      <Button
        variant='contained'
        disabled={true}
        sx={{
          height: '40px',
          width: '150px',
          margin: 'auto',
          backgroundColor: '#282c34',
        }}
      ></Button>
      <Button
        variant='contained'
        disabled={true}
        sx={{
          height: '40px',
          width: '150px',
          margin: 'auto',
          backgroundColor: '#282c34',
        }}
      ></Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Новые столбы освещения
          </Typography>

          <Stack spacing={2} sx={{ marginTop: '10px' }}>
            {props.NewMarkersMapDataArray.map(
              (item: { postID: string; id: string; lng: number; lat: number }) => (
                <Item>{item.postID + '---' + item.id + '---' + item.lng + '---' + item.lat}</Item>
              ),
            )}
          </Stack>

          <Button
            variant='contained'
            // disabled={valueID ? (valueIDError ? true : false) : true}
            sx={{
              height: '40px',
              //   width: '150px',
              //   position: 'absolute',
              //   zIndex: '1',
              display: 'block',
              margin: 'auto',
              marginTop: '40px',
              backgroundColor: '#454545d1',
            }}
            onClick={() => sendNewMarkers()}
          >
            Зарегистрировать
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default NewMarkersGroup;
