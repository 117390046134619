import * as React from 'react';
import { useState, useEffect } from 'react';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import tt, { MapOptions } from '@tomtom-international/web-sdk-maps';
import BasicModal from './modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import NewMarkersGroup from './NewMarkersGroup';
import NewPostModal from './NewPostModal';
import api from '../../api/api';

export default function Map({}) {
  const [resultFetchApiGroups, setResultFetchApiGroups] = useState([]);
  const [resultFetchApiPosts, setResultFetchApiPosts] = useState([]);
  const [resultFetchApiGroupsLastData, setResultFetchApiGroupsLastData] = useState([]);
  const [mapLongitude, setMapLongitude] = useState(71.446);
  const [mapLatitude, setMapLatitude] = useState(51.1801);
  const [mapZoom, setMapZoom] = useState(8);
  const [map, setMap] = useState({});
  const [viewPopup, setviewPopup] = useState(false);
  const [viewModalNewPost, setviewModalNewPost] = useState(false);
  const [Initialized, setInitialized] = useState(false);
  const [FS, setFS] = useState(false);
  const [InitialUpdate, setInitialUpdate] = useState(false);
  const [markersArray, setMarkersArray] = useState([]);
  const [PostMarkersArray, setPostMarkersArray] = useState([]);
  const [TWAreaMarkers, setTWAreaMarkers] = useState([]);
  const [NewMarkerMapData, setNewMarkerMapData] = useState();
  const [LineMarkersToggle, setLineMarkersToggle] = useState(false);
  const [NewMarkersMapDataArray, setNewMarkersMapDataArray] = useState([]);
  const [TWAreaCoords, setTWAreaCoords] = useState([]);
  const isMobileDevice = localStorage.getItem('isMobile');

  const MapOptions = {
    key: 'eiwDf2VOIUcZJT2PvAlBb9saxPtNJ7aP',
    container: 'map',
    center: [mapLongitude, mapLatitude],
    zoom: mapZoom,
  };

  useEffect(() => {
    // const FetchApiTWSensorsAdd = async () => {
    //   const response = await fetch('https://lumen.bikli.kz/Api/api/tw/TWAddCoordinates.php', {
    //     body: JSON.stringify({ ...{ data: data } }),
    //     method: 'POST',
    //   });
    //   const jsonData = await response.json();
    //   console.log(jsonData);
    // };

    FetchApiTWSensors();
    FetchApiGroups();
    FetchApiPosts();
    // FetchApiTWSensorsAdd();
  }, []);

  useEffect(() => {
    if (resultFetchApiGroups.length) {
      console.log(resultFetchApiGroups);

      let map = tt.map(MapOptions);
      setMap(map);
      setInitialized(true);
      setInitialUpdate(!InitialUpdate);
      return () => map.remove();
    }
  }, [resultFetchApiGroups]);

  useEffect(() => {
    if (Initialized) {
      addTWAreaDataPopups();
      addPostMarkers();
      const style = {
        map: 'basic_night',
        poi: 'poi_main',
        trafficIncidents: 'incidents_day',
        trafficFlow: 'flow_relative',
      };
      map.setStyle(style);
      map.addControl(new tt.FullscreenControl());
      map.on('click', function (event) {
        const position = event.lngLat;
        if (event.originalEvent.ctrlKey) {
          closeAllPopups(markersArray);
          newMarker(event);
        } else if (event.originalEvent.altKey) {
          closeAllPopups(markersArray);
          newPost(event);
        }
      });
    }
  }, [InitialUpdate]);

  const FetchApiGroups = async () => {
    const response = await fetch('https://lumen.bikli.kz/Api/api/getGroups.php', {
      method: 'GET',
    });
    const jsonData = await response.json();
    setResultFetchApiGroups(jsonData);
  };

  const FetchApiPosts = async () => {
    const newPostRegResponse = await api.PostsGet();
    setResultFetchApiPosts(newPostRegResponse);
  };

  const FetchApiTWSensors = async () => {
    const response = await fetch('https://lumen.bikli.kz/Api/api/tw/TWGetCoordinates.php', {});
    const jsonData = await response.json();
    setTWAreaCoords(jsonData);
  };

  const closeAllPopups = (markersAr) => {
    markersAr.map((i) => {
      if (i.getPopup().isOpen()) {
        i.togglePopup();
      }
    });
  };

  const addMarkers = () => {
    var popupOffsets = {
      top: [0, 0],
      bottom: [0, -70],
      'bottom-right': [0, -70],
      'bottom-left': [0, -70],
      left: [25, -35],
      right: [-25, -35],
    };

    var markersArr = [];
    for (var i = 0; i < resultFetchApiGroups.length; i += 1) {
      var div = document.createElement('div');
      div.id = resultFetchApiGroups[i].last_value > 1 ? 'LightNormalIcon' : 'LightErrorIcon';

      var marker = new tt.Marker({ element: div, rotation: 0 })
        .setLngLat([resultFetchApiGroups[i].dolgota, resultFetchApiGroups[i].shirota])
        .addTo(map);

      var popup = new tt.Popup({
        offset: popupOffsets,
        closeOnClick: false,
        closeButton: true,
      }).setHTML(
        `Группа ${resultFetchApiGroups[i].groupID} <br> Последнее значение:  ${
          resultFetchApiGroups[i].last_value ? resultFetchApiGroups[i].last_value : 'Нет'
        }`,
      );

      marker.setPopup(popup);
      markersArr.push(marker);
    }
    setMarkersArray(markersArr);
  };

  const addPostMarkers = () => {
    var popupOffsets = {
      top: [0, 0],
      bottom: [0, -70],
      'bottom-right': [0, -70],
      'bottom-left': [0, -70],
      left: [25, -35],
      right: [-25, -35],
    };

    var markersArr = [];
    for (var i = 0; i < resultFetchApiPosts.length; i += 1) {
      var div = document.createElement('div');
      div.id = 'LightPostNormalIcon';

      var marker = new tt.Marker({ element: div, rotation: 0 })
        .setLngLat([resultFetchApiPosts[i].dolgota, resultFetchApiPosts[i].shirota])
        .addTo(map);

      var popup = new tt.Popup({
        offset: popupOffsets,
        closeOnClick: false,
        closeButton: true,
      }).setHTML(`Пост ${resultFetchApiPosts[i].postName}`);

      marker.setPopup(popup);
      markersArr.push(marker);
    }
    setPostMarkersArray(markersArr);
  };

  const addTWAreaDataPopups = () => {
    var popupOffsets = {
      top: [0, 0],
      bottom: [0, 0],
      'bottom-right': [0, -70],
      'bottom-left': [0, -70],
      left: [25, -35],
      right: [-25, -35],
    };

    var markersArr = [];
    for (var i = 0; i < TWAreaCoords.length; i += 1) {
      const coords = JSON.parse(TWAreaCoords[i].CoordinatesData);
      var marker = new tt.Marker({ height: '0px', width: '0px' })
        .setLngLat([coords[0][0], coords[0][1]])
        .addTo(map);

      var popup = new tt.Popup({
        offset: popupOffsets,
        closeOnClick: false,
        closeButton: true,
      }).setHTML(
        `Датчик #${TWAreaCoords[i].SensorID} <br>
        Последнее значение Температуры:  
        ${TWAreaCoords[i].T ? TWAreaCoords[i].T + '°C' : 'Нет'}  <br> 
        Последнее значение Влажности:  ${TWAreaCoords[i].W ? TWAreaCoords[i].W + 'φ' : 'Нет'}`,
      );

      marker.setPopup(popup);
      markersArr.push(marker);
    }
    setTWAreaMarkers(markersArr);
  };

  const newMarker = (data) => {
    var marker = new tt.Marker({}).setLngLat([data.lngLat.lng, data.lngLat.lat]).addTo(map);
    // setviewPopup(true);
    setTimeout(setviewPopup, 500, true);
    let coords = {
      lng: data.lngLat.lng,
      lat: data.lngLat.lat,
    };
    setNewMarkerMapData(coords);
  };

  const newPost = (data) => {
    var marker = new tt.Marker({}).setLngLat([data.lngLat.lng, data.lngLat.lat]).addTo(map);
    setTimeout(setviewModalNewPost, 500, true);
    let coords = {
      lng: data.lngLat.lng,
      lat: data.lngLat.lat,
    };
    setNewMarkerMapData(coords);
  };

  const handleClick = (event) => {
    setFS(!FS);
  };

  const popupsToggle = () => {
    const fs = map.getAttributionControl();
    console.log(fs);
    markersArray.map((i) => {
      i.togglePopup();
    });
  };

  const LinesToggle = () => {
    console.log('LinesToggle');
    if (LineMarkersToggle) {
      markersArray.map((i) => {
        i.remove();
      });
      addPostMarkers();
    } else {
      PostMarkersArray.map((i) => {
        i.remove();
      });
      addMarkers();
    }

    setLineMarkersToggle(!LineMarkersToggle);
  };

  const TWAreasToggle = (e, checked) => {
    TWAreaMarkers.map((i) => {
      i.togglePopup();
    });

    try {
      checked
        ? TWAreaCoords.map((i) => {
            map.addLayer({
              id: i.SensorID.toString(),
              type: 'fill',
              source: {
                type: 'geojson',
                data: {
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates: [JSON.parse(i.CoordinatesData)],
                  },
                },
              },
              layout: {},
              paint: {
                'fill-color': '#56cfab',
                'fill-opacity': 0.3,
                'fill-outline-color': 'black',
              },
            });
          })
        : TWAreaCoords.map((i) => {
            map.removeLayer(i.SensorID.toString());
            map.removeSource(i.SensorID.toString());
          });
    } catch (e) {
      console.log(e);
    }
  };

  const modalClose = () => {
    setviewPopup(false);
    setviewModalNewPost(false);
    setNewMarkerMapData();
  };

  const NewMarkersPush = (object) => {
    var newArr = NewMarkersMapDataArray.push(object);
    setNewMarkersMapDataArray(NewMarkersMapDataArray);
    modalClose();
  };

  const handleChangeToZero = () => {
    // handleChangeTo(0);
    setNewMarkersMapDataArray([]);
  };

  const allWithClass = document.getElementsByClassName('mapboxgl-ctrl-fullscreen');
  allWithClass[0]?.addEventListener('click', handleClick);
  return (
    <>
      <>
        {isMobileDevice != 'true' ? (
          <NewMarkersGroup
            NewMarkersMapDataArray={NewMarkersMapDataArray}
            handleChangeTo={handleChangeToZero}
          />
        ) : null}

        <div
          id='map'
          className='mapDiv'
          style={{
            height: isMobileDevice == 'true' ? 320 : 690,
            width: '100%',
            borderRadius: 10,
            border: '5px solid #454545d1',
            boxShadow:
              '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px -4px 10px 0px rgba(0, 0, 0, 0.12)',
          }}
        >
          {isMobileDevice == 'true' ? (
            FS ? (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      sx={{
                        height: '40px',
                        // width: '150px',
                        position: 'absolute',
                        zIndex: '1',
                        margin: '-10px',
                        marginTop: '15px',
                      }}
                      onChange={popupsToggle}
                      disabled={!LineMarkersToggle}
                    />
                  }
                  label='Данные по группам'
                  sx={{
                    height: '40px',
                    // width: '150px',
                    position: 'absolute',
                    zIndex: '1',
                    margin: '10px',
                    border: '5px solid #676767',
                    padding: '10px',
                    borderRadius: '10px',
                    backgroundColor: '#6767676e',
                    alignItems: 'flex-start',
                  }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      sx={{
                        height: '40px',
                        // width: '150px',
                        position: 'absolute',
                        zIndex: '1',
                        margin: '-10px',
                        marginTop: '15px',
                      }}
                      onChange={LinesToggle}
                    />
                  }
                  label='Линии'
                  sx={{
                    height: '40px',
                    // width: '150px',
                    position: 'absolute',
                    zIndex: '1',
                    margin: '10px',
                    marginTop: '160px',
                    border: '5px solid #676767',
                    padding: '10px',
                    borderRadius: '10px',
                    backgroundColor: '#6767676e',
                    alignItems: 'flex-start',
                  }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      sx={{
                        height: '40px',
                        // width: '150px',
                        position: 'absolute',
                        zIndex: '1',
                        margin: '-10px',
                        marginTop: '15px',
                      }}
                      onChange={TWAreasToggle}
                    />
                  }
                  label='Физ. показатели (°C, φ) '
                  sx={{
                    height: '40px',
                    // width: '150px',
                    position: 'absolute',
                    zIndex: '1',
                    margin: '10px',
                    marginTop: '85px',
                    border: '5px solid #676767',
                    padding: '10px',
                    borderRadius: '10px',
                    backgroundColor: '#6767676e',
                    alignItems: 'flex-start',
                  }}
                />
              </FormGroup>
            ) : null
          ) : (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    sx={{
                      height: '40px',
                      // width: '150px',
                      position: 'absolute',
                      zIndex: '1',
                      margin: '-10px',
                      marginTop: '15px',
                    }}
                    onChange={popupsToggle}
                    disabled={!LineMarkersToggle}
                  />
                }
                label='Данные по группам'
                sx={{
                  height: '40px',
                  // width: '150px',
                  position: 'absolute',
                  zIndex: '1',
                  margin: '10px',
                  border: '5px solid #676767',
                  padding: '10px',
                  borderRadius: '10px',
                  backgroundColor: '#6767676e',
                  alignItems: 'flex-start',
                }}
              />
              <FormControlLabel
                control={
                  <Switch
                    sx={{
                      height: '40px',
                      // width: '150px',
                      position: 'absolute',
                      zIndex: '1',
                      margin: '-10px',
                      marginTop: '15px',
                    }}
                    onChange={LinesToggle}
                  />
                }
                label='Линии'
                sx={{
                  height: '40px',
                  // width: '150px',
                  position: 'absolute',
                  zIndex: '1',
                  margin: '10px',
                  marginTop: '160px',
                  border: '5px solid #676767',
                  padding: '10px',
                  borderRadius: '10px',
                  backgroundColor: '#6767676e',
                  alignItems: 'flex-start',
                }}
              />
              <FormControlLabel
                control={
                  <Switch
                    sx={{
                      height: '40px',
                      // width: '150px',
                      position: 'absolute',
                      zIndex: '1',
                      margin: '-10px',
                      marginTop: '15px',
                    }}
                    onChange={TWAreasToggle}
                  />
                }
                label='Физ. показатели (°C, φ) '
                sx={{
                  height: '40px',
                  // width: '150px',
                  position: 'absolute',
                  zIndex: '1',
                  margin: '10px',
                  marginTop: '85px',
                  border: '5px solid #676767',
                  padding: '10px',
                  borderRadius: '10px',
                  backgroundColor: '#6767676e',
                  alignItems: 'flex-start',
                }}
              />
            </FormGroup>
          )}

          {viewPopup ? (
            NewMarkerMapData ? (
              <BasicModal
                modalClose={modalClose}
                NewMarkersPush={NewMarkersPush}
                NewMarkerMapData={NewMarkerMapData}
                resultFetchApiGroups={resultFetchApiGroups}
                resultFetchApiPosts={resultFetchApiPosts}
              />
            ) : null
          ) : null}

          {viewModalNewPost ? (
            NewMarkerMapData ? (
              <NewPostModal
                modalClose={modalClose}
                NewMarkersPush={NewMarkersPush}
                NewMarkerMapData={NewMarkerMapData}
                resultFetchApiGroups={resultFetchApiGroups}
              />
            ) : null
          ) : null}
        </div>
      </>
    </>
  );
}
