import React, { CSSProperties } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import s from '../Styles/Posts/posts.module.css';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import logo from '../Styles/img/street_lights_1.png';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import BackgroundLayer from '../Styles/img/night_fon.jpg';
import Logo from '../Styles/img/street_lights_1.png';

import Avatar from '@mui/material/Avatar';
import ConnectedLines from '../components/Posts/ConnectedLines';
import ConnectedUsers from '../components/Posts/ConnectedUsers';
import UsersConnect from '../components/Posts/UsersConnect';

import StylesModule from '../Styles/Pages/StreetLightPostPageStyle';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StreetLightPostPage = () => {
  const url = window.location.href.split('/')[4];
  const [value, setValue] = React.useState(0);
  const isMobileDevice = localStorage.getItem('isMobile');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeTo = (newValue: number) => {
    setValue(newValue);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  return (
    <>
      <Box mt={2} mb={2}>
        {/* <Grid container sx={GridContainer}>
            <Grid sx = {GridItem1}>
                <Paper sx = {Paper1}>

                </Paper>
                <Paper sx = {Paper2}>

                </Paper>
            </Grid>
            <Grid sx = {GridItem2}>
                
            </Grid>
        </Grid> */}
        <Grid
          container
          sx={
            isMobileDevice == 'true' ? StylesModule.GridContainerMobile : StylesModule.GridContainer
          }
        >
          <Grid
            sx={isMobileDevice == 'true' ? StylesModule.GridItem1Mobile : StylesModule.GridItem1}
          >
            <Paper sx={StylesModule.Paper1}>
              <Box sx={StylesModule.Box1}>
                <img src={BackgroundLayer} style={{ width: 'inherit', height: 'auto' }}></img>
              </Box>
              <Box sx={StylesModule.Box2}>
                <Box sx={StylesModule.Box3}>
                  <Avatar sx={StylesModule.AvatarStyle}>
                    <img src={Logo} style={{ width: 'inherit', height: 'inherit' }}></img>
                  </Avatar>
                </Box>
                <Box sx={StylesModule.Box4}>
                  <Box sx={StylesModule.Box5}>Коянды</Box>
                  <Box sx={StylesModule.Box6}>
                    <Box sx={StylesModule.Box7}>17 линий</Box>
                    <Box sx={StylesModule.Box7}>Астана</Box>
                    <Box sx={StylesModule.Box7}>Осмотр 17 Июня 2022</Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
            <Paper sx={StylesModule.Paper3}>
              <TabPanel value={value} index={0}>
                Данные
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ConnectedUsers postID={url} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ConnectedLines postID={url} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                История
              </TabPanel>
              <TabPanel value={value} index={4}>
                На карте
              </TabPanel>
              <TabPanel value={value} index={5}>
                Параметры
              </TabPanel>
              <TabPanel value={value} index={6}>
                <UsersConnect postID={url} handleChangeTo={handleChangeTo} />
              </TabPanel>
            </Paper>
          </Grid>
          <Grid
            sx={isMobileDevice == 'true' ? StylesModule.GridItem1Mobile : StylesModule.GridItem2}
          >
            <Paper sx={StylesModule.Paper2}>
              <Tabs
                orientation='vertical'
                variant='scrollable'
                value={value}
                onChange={handleChange}
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                <Tab label='Данные' {...a11yProps(0)} />
                <Tab label='Пользователи' {...a11yProps(1)} />
                <Tab label='Подключеннные линии' {...a11yProps(2)} />
                <Tab label='История' {...a11yProps(3)} />
                <Tab label='На карте' {...a11yProps(4)} />
                <Tab label='Параметры' {...a11yProps(5)} />
                <Tab label='Настройки доступа' {...a11yProps(6)} />
              </Tabs>{' '}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default StreetLightPostPage;
