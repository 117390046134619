import React, { CSSProperties } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import s from '../Styles/Posts/posts.module.css';
import CommonItemsWrapper from '../components/DataTable/CommonItemsWrapper';
const HeadStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginBottom: '20px',
};

type posts = {
  id: number;
  post_name: string;
  lines: number;
  person: string;
};

const StreetLightPosts = () => {
  const [value, setValue] = React.useState(0);
  const isMobileDevice = localStorage.getItem('isMobile');

  function createDataRow(id: number, post_name: string, lines: number, person: string) {
    return { id, post_name, lines, person };
  }

  function createDataCol(
    title: string,
    data: string,
    navigate_to: string,
    align: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined,
  ) {
    return { title, data, navigate_to, align };
  }

  const rows: posts[] = [
    createDataRow(1, 'Коянды', 3, 'Сазонов Р. Б.'),
    createDataRow(2, 'Акан', 14, 'Т. Т. Тим'),
    createDataRow(3, 'Корнекты', 12, 'Иванов Г. А.'),
    createDataRow(4, 'Борисовка', 17, 'Малышева А. И.'),
    createDataRow(5, 'Макинка', 32, 'Панкратов Д. С.'),
    createDataRow(6, 'Сотниковка', 15, 'Егоров В. А.'),
    createDataRow(7, 'Дамса', 21, 'Лазарев А. А.'),
    createDataRow(8, 'Исаковка', 21, 'Карпова З. Е.'),
    createDataRow(9, 'Сейфулино', 16, 'Беляков Е. Ф.'),
    createDataRow(10, 'Теректи', 3, 'Антипов И. Б.'),
    createDataRow(11, 'Таганас', 43, 'Кондратьева К. М.'),
    createDataRow(12, 'Вознесенка', 12, 'Данилова А. И.'),
    createDataRow(13, 'Кульстан', 3, 'Потапова С. Л.'),
    createDataRow(14, 'Моховое', 42, 'Зорина А. А.'),
    createDataRow(15, 'Караузек', 53, 'Сидоров С. Л.'),
    createDataRow(16, 'Кайракты', 6, 'Захарова М. Л.'),
    createDataRow(17, 'Жылымды', 16, 'Филатов А. А.'),
    createDataRow(18, 'Жанкара', 3, 'Климова В. А.'),
  ];

  const columns = [
    createDataCol('#', 'id', 'none', 'left'),
    createDataCol('Пост', 'post_name', '/post/2', 'left'),
    createDataCol('Количество линий', 'lines', 'none', 'center'),
    createDataCol('Ответственный', 'person', 'none', 'left'),
  ];

  return (
    <>
      <Box mt={2} mb={2}>
        <Box sx={HeadStyle}></Box>
        <CommonItemsWrapper rows={rows} columns={columns} />
      </Box>
    </>
  );
};

export default StreetLightPosts;
